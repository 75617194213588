:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}

.no-data{
    width: 100%;
    display: flex;
    justify-content: center;
    color: lightslategray;
}
.detail-price{
    background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.detail-price-unit{
    margin-left: 70px;
    margin-top: -12px;
    font-size: 10px;
}
.product-detail-review{
    margin-top: 50px;
}
.product-detail-review-head{
    display: flex;
    justify-content: space-between;
    font-weight: bold;

}
.product-detail-review-rating{
    display: flex;
    justify-content: space-between;
}
.product-detail-review-rating p{
    font-weight: bold;
}
.product-detail-review-paper{
    width: 400px;
    padding: 10px;
    height: auto;
}
.product-detail-paper-div{
    padding: 10px;
    padding-top: 20px;
    height: auto;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .mob-add-tocart-alert{
        display: block;
        margin-top: 10px;
    }
    .detail-container{
        height: auto;
        min-height: 100vh;
    }
    .cart-add-div{
        display: none;
    }
    .cart-add-div-not-fixed{
        display: none;
    }
    .cart-add-form{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;
    }   
    .cart-add-form-inputs{
        display: flex;
    }
    .quantity-div{
        width: 50%;
        display: flex;
        justify-content: space-around;
        color: lightslategray;
    }
    .quantity-input{
        height: 25px;
        width: 100px;
        outline: none;
        box-shadow: none;
    }
    .price-div{
        display: flex;
        width: 50%;
        justify-content: space-around;
        color: lightslategray;
    }
    .cart-add-button{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .add-to-cart-btn{
        width: 200px;
    }
    .detail-wishlist{
        height: auto;
    }
    .mob-quantity-form-div{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
    }
    .detail-buttons-div{
        padding: 10px;
    }
    #detail-wishlist-btn{
        display: none;
    }
    #mob-add-to-cart-btn{
        border-radius: 25px;
        width: 250px;
    }
    .details-div{
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 10px;
    }
    .product-preview{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .product-preview img{
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
    .product-detail{
        padding: 10px;
        height: auto;
    }
    .product-iframe{
        height: 300px;
        width: 100%;
        padding-top: 10px;   
    }
    .product-loading{
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .product-detail h3{
        font-weight: bold;
    }
    .detail-manufacturer{
        font-size: 12px;
        color: gray;
        margin-top: -10px;
        margin-left: 5px;
    }
    .detail-heading{
        text-decoration: underline;
    }
    .rating-div{
        width: 140px;
        display: flex;
        color: teal;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }
    .rating-div p{
        margin-top: 5px;
    }
    .detail-delivery{
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 10px;
    }
    .detail-delivery img{
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }
    .detail-delivery p{
        font-size: 12px;
        margin-top: 5px;
        font-style: italic;
        background-color:rgb(254, 207, 127);
        padding: 2px 5px 2px 5px;
        color: black;
        border-radius: 5px;
    }
    .description-div{
        height: auto;
        max-height: 250px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .precaution-div{
        height: auto;
        max-height: 200px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .product-detail-accordion-div{
        padding: 10px;
    }
    .product-detail-accordion{
        padding-top: 50px;
    }

    .whatsapp{
        display: flex;
        padding: 5px;
        justify-content: space-around;
        width: 300px;
        margin-left: 25px;
        margin-bottom: 20px;
    }
    .whatsapp img{
        width: 30px;
        height: 30px;
    }
    .whatsapp h4{
        margin-top: 3px;
    }
    .whatsapp-card-icon{
        margin-top: 5px;
    }
    .similar-products-div{
        justify-content: center;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        gap: 5px;
        max-height: 350px;
        overflow-y: scroll;
    }
    .similar-heading{
        font-weight: bold;
        background-color: gray;
        padding: 10px;
        color: white;
    }
    .mob-presc-card{
        height: 130px;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .mob-prescription-div{
        margin-bottom: 10px;
    }
    .mob-controlled-card{
        padding: 10px;
        margin-top: 50px;
        margin-bottom: 40px;
        font-weight: bold;
    }
    .mob-controlled-div{
        text-align: center;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .mob-add-tocart-alert{
        display: block;
        margin-top: 10px;
    }
    .detail-container{
        height: auto;
        min-height: 100vh;
    }
    .cart-add-div{
        display: none;
    }
    .cart-add-div-not-fixed{
        display: none;
    }
    .cart-add-form{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;
    }
    .cart-add-form-inputs{
        display: flex;
    }
    .quantity-div{
        width: 50%;
        display: flex;
        justify-content: space-around;
        color: lightslategray;
    }
    .quantity-input{
        height: 25px;
        width: 100px;
        outline: none;
        box-shadow: none;
    }
    .price-div{
        display: flex;
        width: 50%;
        justify-content: space-around;
        color: lightslategray;
    }
    .cart-add-button{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .add-to-cart-btn{
        width: 200px;
    }
    .details-div{
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 20px;
    }
    .product-preview{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .product-preview img{
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
    .product-detail{
        padding: 10px;
        height: auto;
    }
    .product-loading{
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .product-detail h3{
        font-weight: bold;
    }
    .detail-manufacturer{
        font-size: 12px;
        color: gray;
        margin-top: -10px;
        margin-left: 5px;
    }
    .detail-heading{
        text-decoration: underline;
    }
    .rating-div{
        width: 140px;
        display: flex;
        color: teal;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }
    .rating-div p{
        margin-top: 5px;
    }
    .detail-delivery{
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 10px;
    }
    .detail-delivery img{
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }
    .detail-delivery p{
        font-size: 12px;
        margin-top: 5px;
        font-style: italic;
        background-color:rgb(254, 207, 127);
        padding: 2px 5px 2px 5px;
        color: black;
        border-radius: 5px;
    }
    .description-div{
        height: auto;
        max-height: 250px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .precaution-div{
        height: auto;
        max-height: 200px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .whatsapp{
        display: flex;
        padding: 5px;
        justify-content: space-around;
        width: 300px;
        margin-left: 25px;
        margin-bottom: 20px;
    }
    .whatsapp img{
        width: 30px;
        height: 30px;
    }
    .whatsapp h4{
        margin-top: 3px;
    }
    .whatsapp-card-icon{
        margin-top: 5px;
    }
    .similar-products-div{
        justify-content: center;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        gap: 5px;
        max-height: 350px;
        overflow-y: scroll;
    }
    .similar-heading{
        font-weight: bold;
        background-color: gray;
        padding: 10px;
        color: white;
    }
    .mob-presc-card{
        height: 130px;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .mob-prescription-div{
        margin-bottom: 10px;
    }
    .mob-controlled-card{
        padding: 10px;
        margin-top: 50px;
        margin-bottom: 40px;
        font-weight: bold;
    }
    .mob-controlled-div{
        text-align: center;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .mob-add-tocart-alert{
        display: block;
        margin-top: 10px;
    }
    .detail-container{
        height: auto;
        min-height: 100vh;
    }
    .cart-add-div{
        display: none;
    }
    .cart-add-div-not-fixed{
        display: none;
    }
    .cart-add-paper{
        width: 700px;
    }
    .cart-add-form{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .cart-add-form-inputs{
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: flex-start;
    }
    .quantity-div{
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: lightslategray;
    }
    .qauntity-label{
        padding-top: 12px;
    }
    .quantity-input{
        height: 25px;
        width: 100px;
        outline: none;
        box-shadow: none;
    }
    .price-div{
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: lightslategray;
        
    }
    .cart-add-button{
        display: flex;
        justify-content: center;
    }
    .add-to-cart-btn{
        width: 200px;
    }
    .details-div{
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .product-preview{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .product-preview img{
        width: 250px;
        height: 250px;
        object-fit: cover;
    }
    .product-detail{
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .product-detail-accordion-div{
        padding: 10px;
    }
    .product-detail-accordion{
        padding-top: 50px;
    }
    .description-div{
        height: auto;
        max-height: 250px;
        width: 500px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .precaution-div{
        height: auto;
        max-height: 200px;
        width: 500px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .similar-products-div{
        justify-content: center;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        gap: 5px;
        max-height: 350px;
        overflow-y: scroll;
    }
    .similar-heading{
        font-weight: bold;
        background-color: gray;
        padding: 10px;
        color: white;
    }
    .mob-quantity-form-div{
        display: flex;  
        justify-content: space-between;
    }
    #detail-wishlist-btn{
        display: none;
    }
    .mob-presc-card{
        height: 130px;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .mob-prescription-div{
        margin-bottom: 10px;
    }
    .mob-controlled-card{
        padding: 10px;
        margin-top: 50px;
        margin-bottom: 40px;
        font-weight: bold;
    }
    .mob-controlled-div{
        text-align: center;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .mob-add-tocart-alert{
        display: none;
    }
    .details-div{
        display: flex;
        flex-direction: row;
        margin-top: 50px;
        width: 100%;
    }
    .detail-stock{
        padding-bottom: 10px;
    }
    .product-preview{
        width: 60%;
    }
    .product-detail{
        width: 40%;
        display: flex;
        align-items: flex-start;
    }

    .rating-div{
        padding-bottom: 5px;
    }
    .product-detail-accordion-div{
        height: auto;
        width: 100%;
        padding: 30px 200px 20px 200px;
        display: flex;
        flex-direction: column;
    }
    .product-detail-accordion{
        margin-top: 30px;
    }
    .detail-heading{
        margin-bottom: 20px;
    }
    #detail-wishlist-btn{
        width: 200px;
        display: flex;
    }
    .description-div{
        height: auto;
        max-height: 250px;
        width: 400px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .precaution-div{
        height: auto;
        max-height: 200px;
        width: 400px;
        overflow-y: scroll;
        padding: 7px;
        margin-bottom: 30px;
    }
    .similar-products-div{
        display: none;
    }
    .similar-heading{
        display: none;
    }
    .mob-quantity-label{
        display: none;
    }
    .mob-quantity-form-div{
        display: none;
    }
    #mob-add-to-cart-btn{
        display: none;
    }
    #mob-add-to-wishlist{
        display: none;
    }

    .sticky-div {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
        z-index: 1000;
    }
    .cart-add-paper{
        width: 100%;
        display: flex;
        background-color: whitesmoke;
        justify-content: center;
    }
    .cart-add-form{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 60%;
        background-color: whitesmoke;
    }
    .cart-add-form-inputs{
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: flex-start;
    }
    .price-label{
        color: green;
        font-weight: bold;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .mob-add-tocart-alert{
        display: none;
    }
    .product-detail-desclaimer{
      padding-top: 50px;  
    }
    .desclaimer-heading{
        font-weight: bold;
    }
    .desclaimer-paper{
        padding: 10px;
    }
    .descalimer-heading-div{
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
    }
    .descalimer-heading-div p{
        margin-left: 10px;
    }
    .product-detail-review-date{
        text-align: right;
        color: gray;
    }
    .review-rate{
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
    }
    #review-rate-counter{
        margin-left: 20px;
    }
    #review-dialog-heading{
        font-weight: bold;
    }
    .no-data{
        width: 100%;
        display: flex;
        justify-content: center;
        color: lightslategray;
    }
    .detail-price{
        background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .detail-price-unit{
        margin-left: 70px;
        margin-top: -12px;
        font-size: 10px;
    }
    .product-detail-review{
        margin-top: 50px;
    }
    .product-detail-review-head{
        display: flex;
        justify-content: space-between;
        font-weight: bold;
    
    }
    .product-detail-review-rating{
        display: flex;
        justify-content: space-between;
    }
    .product-detail-review-rating p{
        font-weight: bold;
    }
    .product-detail-review-paper{
        width: 400px;
        padding: 10px;
        height: auto;
    }
    .product-detail-paper-div{
        padding: 10px;
        padding-top: 20px;
        height: auto;
        max-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .sticky-div {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
        z-index: 1000;
    }
    .cart-add-paper{
        width: 100%;
        display: flex;
        background-color: whitesmoke;
        justify-content: center;
    }
    .cart-add-form{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        background-color: whitesmoke;
    }
    .cart-add-form-inputs{
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: flex-start;
    }
    .cart-browse-presc{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        /* background-color: coral; */
        margin-left: 10px;
    }
    .cart-browse-upd-name{
        margin-top: 5px;
        margin-right: 5px;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .cart-add-progress{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        background-color: whitesmoke;
    }
    .cart-add-progress h5{
        margin-left: 20px;
    }
    .cart-add-success{
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: green;
        color: white;
    }
    .cart-add-success h5{
        margin-left: 20px;
    }
    .cart-add-pres-success{
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: green;
        color: white;
    }
    .cart-add-pres-success h5{
        margin-left: 20px;
    }
    .upload-file-name{
        font-weight: bold;
        margin-right: 10px;
    }
    .price-label{
        color: green;
        font-weight: bold;
    }
    .prescription-upload{
        padding-right: 10px;
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .controlled-upload{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        font-weight: bold;
    }
    .controlled-upload-div{
        display: flex;
        justify-content: center;
        width: 500px;
        align-items: center;
    }
    .warning-icon{
        margin-right: 20px;
        color: var(--red-color);
    }
 }