 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .side-show{
        margin-top: 20px;
    }
    .side-show-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    .side-show-paper{
        border-radius: 40px;
        margin-top: 10px;
        height: 200px;
    }
    .side-show-paper img{
        object-fit: contain;
        height: 180px;
        /* margin-top: 10px; */
        cursor: pointer;
    }
    .side-show-featured-products-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top:20px ;
        /* background-color: crimson; */
    }
    .side-show-featured-products{
        height: 500px;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .side-show{
        margin-top: 20px;
    }
    .side-show-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    .side-show-paper{
        border-radius: 40px;
        margin-top: 10px;
    }
    .side-show-paper img{
        object-fit: contain;
        height: 180px;
        /* margin-top: 10px; */
        cursor: pointer;
    }
    .side-show-featured-products-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top:20px ;
        /* background-color: crimson; */
    }
    .side-show-featured-products{
        height: 500px;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
    }
 }
 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .offers-div{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        height: auto;
    }
    .side-show-paper{
        height: 200px;
        width: 200px;
        border-radius: 25px;
        margin-top: 10px;
    }
    .side-show-paper img{
        object-fit: cover;
        height: 200px;
        width: 200px;
        cursor: pointer;
    }
}
 
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .wrapper{
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        height: auto;
    }
    .main-div-area{
        width: 65%;
        height: auto;

    }
    .side-show{
        width: 35%;
        height: auto;
    }
    .offers-div{
        height: auto;
    }
    .featured-products-div{
        height: auto;
    }
    .side-show-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    .side-show-paper{
        height: 200px;
        width: 450px;
        border-radius: 25px;
        margin-top: 10px;
    }
    .side-show-paper img{
        object-fit: none;
        height: 200px;
        cursor: pointer;
    }
    .side-show-featured-products{
        height: auto;
        display: flex;
        justify-content: center;
    }
    .side-show-featured-products-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top:20px;
    }
}
 
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .wrapper{
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        height: auto;
    }
    .main-div-area{
        width: 65%;
        height: auto;

    }
    .side-show{
        width: 35%;
        height: auto;
    }
    .offers-div{
        height: auto;
    }
    .featured-products-div{
        height: auto;
    }
    .side-show-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    .side-show-paper{
        height: 200px;
        width: 450px;
        border-radius: 25px;
        margin-top: 10px;
    }
    .side-show-paper img{
        object-fit: none;
        height: 200px;
        cursor: pointer;
    }
    .side-show-featured-products{
        height: 500px;
        overflow: hidden;
        background-color: white;
    }
    .featuredP-slider{
        width: 400px;
        
    }
    .side-show-featured-products-title{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top:20px;
    }
}