:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .blog-wrapper{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-color: whitesmoke;
        padding: 10px;
    }
    .blog-categories{
        width: 100%;
        background-color: white;
        border-radius: 10px;
    }
    .blog-area{
        width: 100%;
        background-color: white;
        border-radius: 10px;
    }
    .category-title{
        display: flex;
        align-items: center;
        height: 40px;
        padding: 5px;
    }
    .blog-date{
        display: flex;
        justify-content: flex-end;
    }
    .blog-title img{
        width: 250px;
        height: 250px;
    }
    .blog-text{
        padding-top: 10px;
    }
    .blog-body{
        max-height: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        padding-top: 5px;
    }
    .blog-paper{
        padding: 10px;
    }
    .blog-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 10px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .blog-wrapper{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-color: whitesmoke;
        padding: 10px;
    }
    .blog-categories{
        width: 100%;
        background-color: white;
        border-radius: 10px;
    }
    .blog-area{
        width: 100%;
        background-color: white;
        border-radius: 10px;
    }
    .category-title{
        display: flex;
        align-items: center;
        height: 40px;
        padding: 5px;
    }
    .blog-date{
        display: flex;
        justify-content: flex-end;
    }
    .blog-title img{
        width: 250px;
        height: 250px;
    }
    .blog-text{
        padding-top: 10px;
    }
    .blog-body{
        max-height: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        padding-top: 5px;
    }
    .blog-paper{
        padding: 10px;
    }
    .blog-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 10px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .blog-wrapper{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-color: whitesmoke;
        padding: 10px;
    }
    .blog-categories{
        width: 100%;
        background-color: white;
        border-radius: 10px;
    }
    .blog-area{
        width: 100%;
        background-color: white;
        border-radius: 10px;
    }
    .category-title{
        display: flex;
        align-items: center;
        height: 40px;
        padding: 5px;
    }
    .blog-date{
        display: flex;
        justify-content: flex-end;
    }
    .blog-title{
        display: flex;
        padding-bottom: 10px;
    }
    .blog-title img{
        width: 250px;
        height: 100%;
    }
    .blog-date{
        display: flex;
        justify-content: flex-end;
    }
    .blog-text{
        padding-left: 10px;
    }
    .blog-body{
        max-height: 100px;
        overflow: hidden;
        margin-left: 10px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    .blog-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 5px 10px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .blog-wrapper{
        display: flex;
        flex-direction: row;
        min-height: 205vh;
        background-color: whitesmoke;
        justify-content: space-between;
        padding: 5px;
    }
    .blog-categories{
        width: 20.5%;
        background-color: white;
    }
    .blog-area{
        width: 100%;
        background-color: white;
    }
    .category-title{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        padding: 5px;
        font-weight: bold;
        color: var(--text-black-color);
        border-bottom: 1px dotted;
    }
    .blog-category-element{
        padding: 10px;
        display: flex;
        align-items: center;
        height: 40px;
        cursor: pointer;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .blog-category-element:hover{
        background-color: var(--light-red-color);
        font-weight: bold;
        color: white;
    }
    .blog-area{
        padding: 10px;
    }
    .blog-paper{
        padding: 10px;
    }
    .blog-title{
        display: flex;
        padding-bottom: 10px;
    }
    .blog-title img{
        width: 250px;
        height: 100%;
    }
    .blog-date{
        display: flex;
        justify-content: flex-end;
    }
    .blog-text{
        padding-left: 10px;
    }
    .blog-body{
        max-height: 100px;
        overflow: hidden;
        margin-left: 10px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    .blog-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 5px 10px;
    }
    .blogNotloggedIn{
        width: 300px;
        display: flex;
        justify-content: flex-end;
    }
    .blogNotLoggedInIcon{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .blogNotLoggedInDiv{
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}