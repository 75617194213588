
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .offers-div{
        padding: 10px;
    }
    .offer-paper{
        width: 350px;
        height: 200px;
    }
    .offer-paper img{
        width: 350px;
        height: 200px;
        object-fit: cover;
    }
    .offers-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap:20px;
        height: auto;
        width: 100%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}
 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .offers-div{
        padding: 10px;
    }
    .offer-paper{
        width: 250px;
        height: 300px;
    }
    .offer-paper img{
        width: 250px;
        height: 300px;
        object-fit: cover;
    }
    .offers-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap:20px;
        height: auto;
        width: 100%;
    }
}
 
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .offers-div{
        padding: 10px;
    }
    .offer-paper{
        width: 250px;
        height: 300px;
    }
    .offer-paper img{
        width: 250px;
        height: 300px;
        object-fit: cover;
    }
    .offers-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap:20px;
        height: auto;
        width: 100%;
    }
}
 
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .offers-div{
        padding: 10px;
    }
    .offer-paper{
        width: 250px;
        height: 300px;
    }
    .offer-paper img{
        width: 250px;
        height: 300px;
        object-fit: cover;
    }
    .offers-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap:20px;
        height: auto;
        width: 100%;
    }
}