:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .order-tab{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 20px 50px 20px;
    }
    .order-card{
        width: 380px;
        height: auto;
        min-height: 450px;
        padding: 10px;
        margin-top: 30px;
    }
    .order-card-id{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .order-card-id p{
        font-size: 15px;
        color: var(--text-black-color);
        font-weight: bold;
        width: 40%;
    }
    .order-card-id p span{
        font-weight: normal;
    }
    .order-card-details{
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .order-number{
        background-color: whitesmoke;
        padding: 10px;
        flex: 1;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-number p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-number h5{
        /* margin-left: 20px; */

    }
    .order-amount{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-amount p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-amount h5{
        /* margin-left: 20px; */
    }
    .order-amount h5 span{
        font-size: 15px;
    }
    .order-item-number{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-item-number p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-item-number h5{
        /* margin-left: 20px; */
    }
    .order-status{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-status p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-status h5{
        /* margin-left: 20px; */
    }
    .order-tracker{
        height: 100px;
        display: flex;
        align-items: center;
    }
    .order-bar-div{
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 10px; */
    }
    .order-bar{
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
        border: 1px dotted;
    }
    .order-bar-active{
        background-color: #4BB543;
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
    }
    .order-bar-icon{
        background-color: whitesmoke;
        position: absolute;
        height: 40px;
        width: 40px;
        top: 30%;
        left: 28%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
    }
    .order-bar-icon-active{
        background-color: #4BB543;
        position: absolute;
        height: 40px;
        width: 40px;
        top: 30%;
        left: 28%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
        color: white;
    }
    .order-bar-status{
        position: absolute;
        height: 10px;
        padding-top: 5px;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: #96D2C8; */
    }
    .order-bar-status p{
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-items{
        background-color: whitesmoke;
        height: 170px;
        width: 100%;
        margin-top: 40px;
        display: flex;
        padding: 10px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .order-item-card{
        width: 250px;
        height: 100%;
        display: flex;
        background-color: coral;
        margin-left: 10px;
    }
    .order-item-img{
        height: 100%;
        width: 120px;
    }
    .order-item-img img{
        width: 120px;
    }
    .order-item-detail{
        padding: 5px;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        color: var(--text-black-color);
    }
    #order-item-name{
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        max-height: 2.4em;
    }
    .order-delete-card{
        width: 300px;
    }
    .order-delete-div{
        padding: 10px;
        text-align: center;
    }
    .order-delete-div p{
        color: var(--text-black-color);
    }
    .order-delete-icon-div{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .order-delete-action{
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 10px;
    }
    .completed-orders-btn-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        
    }
    .completed-orders-btn{
        display: flex;
        justify-content: space-between;
        width: 150px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .order-tab{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 20px 50px 20px;
    }
    .order-card{
        width: 380px;
        height: auto;
        min-height: 450px;
        padding: 10px;
        margin-top: 30px;
    }
    .order-card-id{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .order-card-id p{
        font-size: 15px;
        color: var(--text-black-color);
        font-weight: bold;
        width: 40%;
    }
    .order-card-id p span{
        font-weight: normal;
    }
    .order-card-details{
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .order-number{
        background-color: whitesmoke;
        padding: 10px;
        flex: 1;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-number p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-number h5{
        /* margin-left: 20px; */

    }
    .order-amount{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-amount p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-amount h5{
        /* margin-left: 20px; */
    }
    .order-amount h5 span{
        font-size: 15px;
    }
    .order-item-number{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-item-number p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-item-number h5{
        /* margin-left: 20px; */
    }
    .order-status{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-status p{
        /* font-size: 15px; */
        text-decoration: underline;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-status h5{
        /* margin-left: 20px; */
    }
    .order-tracker{
        height: 100px;
        display: flex;
        align-items: center;
    }
    .order-bar-div{
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 10px; */
    }
    .order-bar{
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
        border: 1px dotted;
    }
    .order-bar-active{
        background-color: #4BB543;
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
    }
    .order-bar-icon{
        background-color: whitesmoke;
        position: absolute;
        height: 40px;
        width: 40px;
        top: 30%;
        left: 28%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
    }
    .order-bar-icon-active{
        background-color: #4BB543;
        position: absolute;
        height: 40px;
        width: 40px;
        top: 30%;
        left: 28%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
        color: white;
    }
    .order-bar-status{
        position: absolute;
        height: 10px;
        padding-top: 5px;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: #96D2C8; */
    }
    .order-bar-status p{
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-items{
        background-color: whitesmoke;
        height: 170px;
        width: 100%;
        margin-top: 40px;
        display: flex;
        padding: 10px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .order-item-card{
        width: 250px;
        height: 100%;
        display: flex;
        background-color: coral;
        margin-left: 10px;
    }
    .order-item-img{
        height: 100%;
        width: 120px;
    }
    .order-item-img img{
        width: 120px;
    }
    .order-item-detail{
        padding: 5px;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        color: var(--text-black-color);
    }
    #order-item-name{
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        max-height: 2.4em;
    }
    .order-delete-card{
        width: 300px;
    }
    .order-delete-div{
        padding: 10px;
        text-align: center;
    }
    .order-delete-div p{
        color: var(--text-black-color);
    }
    .order-delete-icon-div{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .order-delete-action{
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 10px;
    }
    .completed-orders-btn-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .completed-orders-btn{
        display: flex;
        justify-content: space-between;
        width: 150px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .order-tab{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 20px 50px 20px;
    }
    .order-card{
        width: 750px;
        height: auto;
        min-height: 450px;
        padding: 10px;
        margin-top: 30px;
    }
    .order-card-id{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .order-card-id p{
        font-size: 15px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .order-card-id p span{
        font-weight: normal;
    }
    .order-card-details{
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .order-number{
        background-color: whitesmoke;
        padding: 10px;
        flex: 1;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-number p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-number h5{
        margin-left: 20px;
    }
    .order-amount{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-amount p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-amount h5{
        margin-left: 20px;
    }
    .order-amount h5 span{
        font-size: 15px;
    }
    .order-item-number{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-item-number p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-item-number h5{
        margin-left: 20px;
    }
    .order-status{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-status p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-status h5{
        margin-left: 20px;
    }
    .order-tracker{
        height: 100px;
        display: flex;
        align-items: center;
    }
    .order-bar-div{
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .order-bar{
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
        border: 1px dotted;
    }
    .order-bar-active{
        background-color: #4BB543;
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
    }
    .order-bar-icon{
        background-color: whitesmoke;
        position: absolute;
        height: 45px;
        width: 45px;
        top: 28%;
        left: 41%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
    }
    .order-bar-icon-active{
        background-color: #4BB543;
        position: absolute;
        height: 45px;
        width: 45px;
        top: 28%;
        left: 41%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
        color: white;
    }
    .order-bar-status{
        position: absolute;
        height: 30px;
        padding-top: 5px;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .order-bar-status p{
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-items{
        background-color: whitesmoke;
        height: 170px;
        width: 100%;
        margin-top: 40px;
        display: flex;
        padding: 10px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .order-item-card{
        width: 250px;
        height: 100%;
        display: flex;
        background-color: coral;
        margin-left: 10px;
    }
    .order-item-img{
        height: 100%;
        width: 120px;
    }
    .order-item-img img{
        width: 120px;
    }
    .order-item-detail{
        padding: 5px;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        color: var(--text-black-color);
    }
    #order-item-name{
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        max-height: 2.4em;
    }
    .order-delete-card{
        width: 300px;
    }
    .order-delete-div{
        padding: 10px;
        text-align: center;
    }
    .order-delete-div p{
        color: var(--text-black-color);
    }
    .order-delete-icon-div{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .order-delete-action{
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 10px;
    }
    .completed-orders-btn-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .completed-orders-btn{
        display: flex;
        justify-content: space-between;
        width: 150px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .order-tab{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 50px 50px 50px;
    }
    .order-card{
        width: 800px;
        height: auto;
        min-height: 450px;
        padding: 10px;
        margin-top: 30px;
    }
    .order-card-id{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .order-card-id p{
        font-size: 15px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .order-card-id p span{
        font-weight: normal;
    }
    .order-card-details{
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .order-number{
        background-color: whitesmoke;
        padding: 10px;
        flex: 1;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-number p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-number h5{
        margin-left: 20px;
    }
    .order-amount{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-amount p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-amount h5{
        margin-left: 20px;
    }
    .order-amount h5 span{
        font-size: 15px;
    }
    .order-item-number{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-item-number p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-item-number h5{
        margin-left: 20px;
    }
    .order-status{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-status p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-status h5{
        margin-left: 20px;
    }
    .order-tracker{
        height: 100px;
        display: flex;
        align-items: center;
    }
    .order-bar-div{
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .order-bar{
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
        border: 1px dotted;
    }
    .order-bar-active{
        background-color: #4BB543;
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
    }
    .order-bar-icon{
        background-color: whitesmoke;
        position: absolute;
        height: 45px;
        width: 45px;
        top: 28%;
        left: 41%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
    }
    .order-bar-icon-active{
        background-color: #4BB543;
        position: absolute;
        height: 45px;
        width: 45px;
        top: 28%;
        left: 41%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
        color: white;
    }
    .order-bar-status{
        position: absolute;
        height: 30px;
        padding-top: 5px;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .order-bar-status p{
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-items{
        background-color: whitesmoke;
        height: 170px;
        width: 100%;
        margin-top: 40px;
        display: flex;
        padding: 10px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .order-item-card{
        width: 250px;
        height: 100%;
        display: flex;
        background-color: coral;
        margin-left: 10px;
    }
    .order-item-img{
        height: 100%;
        width: 120px;
    }
    .order-item-img img{
        width: 120px;
    }
    .order-item-detail{
        padding: 5px;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        color: var(--text-black-color);
    }
    #order-item-name{
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        max-height: 2.4em;
    }
    .order-delete-card{
        width: 300px;
    }
    .order-delete-div{
        padding: 10px;
        text-align: center;
    }
    .order-delete-div p{
        color: var(--text-black-color);
    }
    .order-delete-icon-div{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .order-delete-action{
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 10px;
    }
    .completed-orders-btn-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .completed-orders-btn{
        display: flex;
        justify-content: space-between;
        width: 150px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .order-tab{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 50px 50px 50px;
    }
    .order-card{
        width: 800px;
        height: auto;
        min-height: 450px;
        padding: 10px;
        margin-top: 30px;
    }
    .order-card-id{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .order-card-id p{
        font-size: 15px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .order-card-id p span{
        font-weight: normal;
    }
    .order-card-details{
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .order-number{
        background-color: whitesmoke;
        padding: 10px;
        flex: 1;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-number p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-number h5{
        margin-left: 20px;
    }
    .order-amount{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-amount p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-amount h5{
        margin-left: 20px;
    }
    .order-amount h5 span{
        font-size: 15px;
    }
    .order-item-number{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-item-number p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-item-number h5{
        margin-left: 20px;
    }
    .order-status{
        background-color: whitesmoke;
        flex: 1;
        padding: 10px;
        margin: 0 5px;
        border-radius: 10px;
    }
    .order-status p{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-status h5{
        margin-left: 20px;
    }
    .order-tracker{
        height: 100px;
        display: flex;
        align-items: center;
    }
    .order-bar-div{
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .order-bar{
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
        border: 1px dotted;
    }
    .order-bar-active{
        background-color: #4BB543;
        height: 7px;
        width: 100%;
        border-radius: 3.5px;
        top: 50%;
        left: 0;
        margin-top: auto;
        position:absolute;
    }
    .order-bar-icon{
        background-color: whitesmoke;
        position: absolute;
        height: 45px;
        width: 45px;
        top: 28%;
        left: 41%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
    }
    .order-bar-icon-active{
        background-color: #4BB543;
        position: absolute;
        height: 45px;
        width: 45px;
        top: 28%;
        left: 41%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px thin;
        color: white;
    }
    .order-bar-status{
        position: absolute;
        height: 30px;
        padding-top: 5px;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .order-bar-status p{
        font-weight: bold;
        color: var(--text-black-color);
    }
    .order-items{
        background-color: whitesmoke;
        height: 170px;
        width: 100%;
        margin-top: 40px;
        display: flex;
        padding: 10px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .order-item-card{
        width: 250px;
        height: 100%;
        display: flex;
        background-color: coral;
        margin-left: 10px;
    }
    .order-item-img{
        height: 100%;
        width: 120px;
    }
    .order-item-img img{
        width: 120px;
    }
    .order-item-detail{
        padding: 5px;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        color: var(--text-black-color);
    }
    #order-item-name{
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        max-height: 2.4em;
    }
    .order-delete-card{
        width: 300px;
    }
    .order-delete-div{
        padding: 10px;
        text-align: center;
    }
    .order-delete-div p{
        color: var(--text-black-color);
    }
    .order-delete-icon-div{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .order-delete-action{
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 10px;
    }
    .completed-orders-btn-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: coral; */
        margin-bottom: 40px;
    }
    .completed-orders-btn{
        display: flex;
        justify-content: space-between;
        width: 150px;
    }
    .order-filter-card{
        padding: 5px;
    }
    .order-filter-card p{
        font-weight: bold;
        color: var(--text-black-color);
    }
}