:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}

.referral-div{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    height: 100vh;
}
.referral-paper{
    padding: 15px;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
}