:root{
    --red-color:#EE1C22;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}
@keyframes color-pulse {
  0% {
    color: red;
  }
  25% {
    color: blue;
  }
  50% {
    color: green;
  }
  75% {
    color: yellow;
  }
  100% {
    color: red;
  }
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 0;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .navigation-div{
        display: none;
    }
    .mb-menu{
        position: relative;
        background-color: lightcoral;
    }
    .menu-icon-div-on{
        padding-left: 5px;
        padding-top: 3px;
        height: 40px;
        /* position: relative; */
        display: block;
    }
    .menu-icon-div-off{
        display: none;
    }
    .menu-items-opened{
        background-color: red;
        display: block;
        width: 200px;
        height: 300px;
        position: absolute;
        z-index: 2;
        transition: width 2.5s ease-out;

    }
    .menu-items-closed{
        width: 0px;
        display: none;
    }
    .welcome-tag{
        font-weight: bold;
        font-size: 20px;
    }
    .auth-list-item a{
        text-decoration: none;
        width: 100%;
    }
    .auth-list-item-div{
        display: flex;
        justify-content: flex-start;
        padding-left: 12px;
        padding-top: 5px;
        height: 70px;
        width: 100%;
        border-bottom: 1px solid lightgray;
    }
    .auth-list-item-text{
        height: 50px;
        width: 150px;
        margin-left: 10px;
        padding-left: 20px;
        text-decoration: none;
        color: gray;
    }
    .auth-list-item a{
        text-decoration: none;
    }
    .auth-menu-item{
        display: flex;
        justify-content: space-around;
    }
    .account-icon{
        margin-top: 10px;
        color: gray;
    }
    .mob-link-list-item{
        height: 50px;
    }
    .mob-link-list-item a{
        text-decoration: none;
        width: 100%;
        height: 100%;
        color: gray;
    }
    .mob-link{
        display: flex;
        padding-top: 10px;
        font-weight: bold;
    }
    .mob-link-icon{
        margin-right: 25px
    }
    .mob-nav-footer{
        text-align: center;
        color: white;
        height: 100px;
        width: 100%;
        padding-top: 10px;
        background-color:#EE1C22;
    }

    .spec-navigation-dropdlink-auth{
        width: 70px;
        background-color: coral;
    }
    .auth-menu-item-spacer{
        width: 10px;
        height: 2px;
        background-color: none;
    }
    .auth-menu-item{
        color: lightslategray;
    }
    .auth-menu a{
        text-decoration: none;
    }

    /* FOOTER */
    .partners{
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .partners h3{
        margin-bottom: 30px;
    }
    .partner-logos{
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
        margin-bottom: 50px;
        overflow-wrap: wrap;
        background-color: coral;
        height: auto;
    }
    .partner-logos-img{
        width: 150px;
        height: 50px;
        object-fit: contain;
    }
    .footer-div{
        display: flex;
        flex-direction: column;
    }
    /* .footer-tagline{
        background-color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        padding-bottom: 20px;
    } */
    .footer-tagline{
        background-color: var(--text-black-color);
        text-align: center;
        height: 330px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 30px 10px 30px 10px;
        color: white;
    }
    .footer-tagline h2{
        word-spacing: 5px;
    }
    .footer-tags-div{
        padding: 20px 5px 50px 5px;
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-tags{
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex:1;
        width: auto;
    }
    .footer-tags p{
        font-size: 15px;
    }
    .footer-heat-feeder{
        height: 100px;
        display: flex;
        background-color:#EE1C22;
        color: white;
    }
    .productss{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .productss h1{
        font-weight: 1000;
    }
    .visitors{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .visitors h1{
        font-weight: 1000;
    }
    .deliveries{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
    }
    .deliveries h1{
        font-weight: 1000;
    }
    .footer-nav{
        display: none;
        padding-top: 10px;
        background-color: whitesmoke;
        height: 200px;
    }
    .footer-nav-title{
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 15px;
    }
    .know-us{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .know-us ul{
        list-style-type: none;
    }
    .know-us ul li a{
        text-decoration: none;
    }
    .policies{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .policies ul{
        list-style-type: none;
    }
    .policies ul li a{
        text-decoration: none;
    }
    .services{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .services ul{
        list-style-type: none;
    }
    .services ul li a{
        text-decoration: none;
    }
    .connect{
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .connect p{
        font-weight: bold;
        font-size: 15px;
    }
    .download-link{
        padding-bottom: 10px;
    }
    .download-link form label{
        font-weight: bold;
        color:gray;
        padding-bottom: 10px;
    }
    .footer-tel-email{
        display: flex;
        height: 100px;
        justify-content: center;
        padding: 10px;
        flex-direction: column;
    }
    .footer-tel{
        display: flex;
        align-items: center;
        width: 100%;
        height: 50%;
        /* background-color: coral; */
        justify-content: center;
    }
    .footer-tel p span{
        font-weight: bold;
    }
    .footer-email{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .footer-email p span{
        font-weight: bold;
    }
    .footer-socials{
        display: flex;
        width: 200px;
        justify-content: space-around;
    }
    .footer-socials img{
        cursor: pointer;
    }
    .footer-rights{
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* MOBILE FOOTER NAV */
    .mob-footer{
        min-height: 200px;
        /* background-color: crimson; */
        display: flex;
        flex-direction: column;
    }
    .mob-footer-nav-link{
        text-decoration: none;
        color: black;
        font-weight: bold;
    }
    .mob-footer-menu-item{
        height: 40px;
        /* border-bottom:1px solid gray; */
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
    }
    .mob-footer-menu-item-alt{
        background-color: whitesmoke;
        height: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
    }
    .footer-rights{
        background-color: whitesmoke;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .partners{
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .partners h3{
        margin-bottom: 30px;
    }
    .partner-logos{
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
        margin-bottom: 50px;
        overflow-wrap: wrap;
        height: auto;
    }
    .partner-logos-img{
        width: 150px;
        height: 50px;
        object-fit: contain;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navigation-div{
        display: none;
    }
    .spec-navigation{
        display: none;
    }
    .mb-menu{
        position: relative;
        background-color: lightcoral;
    }
    .menu-icon-div-on{
        padding-left: 5px;
        padding-top: 3px;
        height: 40px;
        /* position: relative; */
        display: block;
    }
    .menu-icon-div-off{
        display: none;
    }
    .menu-items-opened{
        background-color: red;
        display: block;
        width: 200px;
        height: 300px;
        position: absolute;
        z-index: 2;
        transition: width 2.5s ease-out;

    }
    .menu-items-closed{
        width: 0px;
        display: none;
    }
    .welcome-tag{
        font-weight: bold;
        font-size: 20px;
    }
    .auth-list-item a{
        text-decoration: none;
        width: 100%;
    }
    .auth-list-item-div{
        display: flex;
        justify-content: flex-start;
        /* background-color: coral; */
        padding-left: 12px;
        padding-top: 5px;
        height: 70px;
        width: 100%;
        border-bottom: 1px solid lightgray;
    }
    .auth-list-item-text{
        /* background-color: coral; */
        height: 50px;
        width: 150px;
        margin-left: 10px;
        padding-left: 20px;
        text-decoration: none;
        color: gray;
    }
    .auth-list-item a{
        text-decoration: none;
    }
    .account-icon{
        margin-top: 10px;
        color: gray;
    }
    .mob-link-list-item{
        height: 50px;
    }
    .mob-link-list-item a{
        text-decoration: none;
        width: 100%;
        height: 100%;
        color: gray;
    }
    .mob-link{
        display: flex;
        padding-top: 10px;
        font-weight: bold;
    }
    .mob-link-icon{
        margin-right: 25px
    }
    .mob-nav-footer{
        text-align: center;
        color: white;
        height: 100px;
        width: 100%;
        padding-top: 10px;
        background-color:#EE1C22;
    }

    /* FOOTER */
    .partners{
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .partners h3{
        margin-bottom: 40px;
    }
    .partner-logos{
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .partner-logos-img{
        width: 250px;
        height: 100px;
        object-fit: contain;
    }
    .footer-div{
        display: flex;
        flex-direction: column;
    }
    .footer-tagline{
        background-color: var(--text-black-color);
        text-align: center;
        height: 330px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 10px 30px 10px;
        color: white;
    }
    .footer-tagline h2{
        word-spacing: 5px;
    }
    .footer-tags-div{
        padding: 30px 10px 50px 10px;
        height: auto;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-tags{
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex:1;
        width: auto;
    }
    .footer-tags p{
        font-size: 15px;
    }
    .footer-heat-feeder{
        height: 100px;
        display: flex;
        background-color:#EE1C22;
        color: white;
    }
    .productss{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .productss h1{
        font-weight: 1000;
    }
    .visitors{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .visitors h1{
        font-weight: 1000;
    }
    .deliveries{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
    }
    .deliveries h1{
        font-weight: 1000;
    }
    .footer-nav{
        display: none;
        padding-top: 10px;
        background-color: whitesmoke;
        height: 200px;
    }
    .footer-nav-title{
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 15px;
    }
    .know-us{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .know-us ul{
        list-style-type: none;
    }
    .know-us ul li a{
        text-decoration: none;
    }
    .policies{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .policies ul{
        list-style-type: none;
    }
    .policies ul li a{
        text-decoration: none;
    }
    .services{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .services ul{
        list-style-type: none;
    }
    .services ul li a{
        text-decoration: none;
    }
    .connect{
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .connect p{
        font-weight: bold;
        font-size: 15px;
    }
    .download-link{
        padding-bottom: 10px;
    }
    .download-link form label{
        font-weight: bold;
        color:gray;
        padding-bottom: 10px;
    }
    .footer-tel-email{
        display: flex;
        height: 100px;
        justify-content: center;
        padding: 10px;
        flex-direction: column;
    }
    .footer-tel{
        display: flex;
        align-items: center;
        width: 100%;
        height: 50%;
        /* background-color: coral; */
        justify-content: center;
    }
    .footer-tel p span{
        font-weight: bold;
    }
    .footer-email{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .footer-email p span{
        font-weight: bold;
    }
    .footer-socials{
        display: flex;
        width: 200px;
        justify-content: space-around;
    }
    .footer-socials img{
        cursor: pointer;
    }
    .footer-rights{
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* MOBILE FOOTER NAV */
    .mob-footer{
        min-height: 200px;
        display: flex;
        flex-direction: column;
    }
    .mob-footer-nav-link{
        text-decoration: none;
        color: black;
        font-weight: bold;
    }
    .mob-footer-menu-item{
        height: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
    }
    .mob-footer-menu-item-alt{
        background-color: whitesmoke;
        height: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
    }
    .footer-rights{
        background-color: whitesmoke;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .discount-div{
        height: 250px;
        width: 100%;
    }
    .navigation-bar{
        width: 100%;
        font-family: 'Roboto';
    }
    .navigation-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        height: 50px;
        border-bottom: 1px dotted;
    }
    .info{
        display: flex;
        width: 330px;
        justify-content: space-between;
        align-items: center;
        margin-left: 90px;
    }
    .advert{
        font-weight:bolder;
        background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Noto Sans JP", sans-serif;
        cursor: pointer;
    }
    .faq{
        min-width: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navigation-auth{
        padding: 20px 30px 20px 30px;
        display: flex;
        width: 100%;
        height: 122px;
        justify-content: flex-start;
    }
    .logo img{
        width: 150px;
    }
    .nav-search{
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 500px;
    }
    .nav-search-in-div{
        background-color: whitesmoke;
        width: 350px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        color: gray;
        text-decoration: none;
    }
    .nav-search-input{
        width: 350px;
    }
    .auth-wish-cart{
        display: flex;
        width: 600px;
        padding: 0px 0px 0px 20px;
        justify-content: space-evenly;
        
    }
    .auth-wish-cart img{
        width: 30px;
    }
    .auth-wish-cart a{
        text-decoration: none;
    }
    .auth{
        display: flex;
        flex-direction: column;
        color: gray;
    }
    .auth img{
        width: 30px;
    }
    .wish{
        color: gray;
    }
    .wish h3{
        text-decoration: none;
    }
    .cart{
        color: gray;
        text-decoration: none;
    }
    .cart h3{
        text-decoration: none;
    }
    .navigation-tabs{
        width: 100%;
        height: 40px;
        background-color: #ff6961;
        display: flex;
        flex-direction: row;
        transition: all linear 0.3s;
    }
    .navigation-tab{
        position: relative;
        margin-left: 400px;
        display: flex;
        width: auto;
    }
    .nav-tab{
        min-width: 100px;
        display: flex;
        padding: 0px 10px 0px 10px;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-weight: bold;
        color:white;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }
    .nav-tab a{
        color: white;
        text-decoration: none;
    }
    .nav-tab:hover{
        border-bottom: 3px solid;
    }
    .special-nav-overlay{
        position: absolute;
        top:172px;
        left:850px;
        z-index: 3;
        background-color: var(--red-color);
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }
    .special-nav-tab{
        min-width: 300px;
        height: 100px;
        position: absolute;
        top: 140px;
        left: 780px;
        z-index: 2;
        border-radius: 2px;
        background-color: var(--light-red-color);
        animation: pulse 3s infinite;
        color: white;
    }
    .special-nav-tab::before, .special-nav-tab::after{
        opacity: 0;
        animation: pulse 3s infinite;
        position: absolute;        
    }
    .special-nav-tab::before{
        background-color: var(--light-red-color);
        animation-delay: 2s;        
    }
    .special-nav-tab::after{
        background-color: var(--light-red-color);
        animation-delay: 2s;        
    }

    .special-nav-tab-2{
        min-width: 250px;
        height: 70px;
        position: absolute;
        top: 155px;
        left: 822px;
        z-index: 2;
        background-color: var(--light-red-color);
        animation: pulse 3s infinite; 
    }
    .special-nav-tab-2::before, .special-nav-tab-2::after{
        opacity: 0;
        animation: pulse 2s infinite;
        position: absolute;        
    }
    .special-nav-tab-2::before{
        background-color: var(--light-red-color);
        animation-delay: 0.5s;  
    }
    .special-nav-tab-2::after{
        background-color: var(--light-red-color);
        animation-delay: 0.5s;    
    }
    .browse{
        background-color: var(--red-color);
        width: 270px;
        display: flex;
        padding: 10px 20px 10px 20px;
        color: white;
        justify-content: space-between;
        align-items: center ;
        transition: all linear 1s;
        height: 40px;
        font-weight: bold;
        font-size: 17px;
        cursor: pointer;
    }
    .navigation-dropdown{
        position: absolute;
        margin-left: 50px;
        cursor: pointer;
        color: #2c2c2c;
        transition: all linear 0.3s;
    }
    .navigation-dropdown-panel-div{
        display: flex;
        width: 400px;
        height: auto;
    }
    .navigation-dropdown-panel{
        height: auto;
        width: auto;
        position: relative;
        z-index: 5;
        background-color: white;
        padding: 5px;
        /* transition: all linear 1s; */
        /* border-radius: 25px; */
    }
    .navigation-dropdown-child{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 15px;
        height: 45px;
        color: #54595F;
        /* background-color: coral; */
    }
    .navigation-dropdown-child:hover{
        background-color: whitesmoke;
    }
    .navigation-dropdown-child-img{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
    }
    .navigation-dropdown-child-img img{
        width: 25px;
        height: 25px;
    }
    .navigation-dropdown-child-p{
        /* background-color: black; */
        color: black;
        width: 200px;
        padding-left: 20px;
        font-size: 15px;
    }
    .navigation-dropdown-child-icon{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #ff6961;
    }
    .navigation-side-panel-family-planning{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 265px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .family_planning_text{
        font-weight: bold;
        color: black;
    }
    .family_planning_products{
        min-height: 30px;
        padding-left: 10px 0px 10px 0px;
        color: var(--text-black-color);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .family_planning_products:hover{
        background-color: whitesmoke;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px 10px 0px;
        
    }
    .navigation-side-panel-cosmetics{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -180px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-cough-flu{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 90px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-health-devices{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -360px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-supplements{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -270px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-anti-malaria{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 180px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-pain{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 0px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-stomach{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -90px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }

    /* FOOTER */
    .footer-div{
        display: flex;
        flex-direction: column; 
    }
    .footer-tagline{
        background-color: var(--text-black-color);
        text-align: center;
        height: 330px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 10px 30px 10px;
        color: white;
    }
    .footer-tagline h2{
        word-spacing: 5px;
    }
    .footer-tags-div{
        padding: 30px 10px 50px 10px;
        height: auto;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-tags{
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex:1;
        width: auto;
    }
    .footer-tags p{
        font-size: 15px;
    }
    .footer-heat-feeder{
        height: 100px;
        display: flex;
        background-color:#EE1C22;
        color: white;
    }
    .productss{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .productss h1{
        font-weight: 1000;
    }
    .visitors{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .visitors h1{
        font-weight: 1000;
    }
    .deliveries{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
    }
    .deliveries h1{
        font-weight: 1000;
    }
    .footer-nav{
        display: flex;
        padding-top: 10px;
        background-color: whitesmoke;
        height: 200px;
    }
    .footer-nav-title{
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 15px;
    }
    .know-us{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .know-us ul{
        list-style-type: none;
    }
    .know-us ul li a{
        text-decoration: none;
    }
    .policies{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .policies ul{
        list-style-type: none;
    }
    .policies ul li a{
        text-decoration: none;
    }
    .services{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .services ul{
        list-style-type: none;
    }
    .services ul li a{
        text-decoration: none;
    }
    .connect{
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .connect p{
        font-weight: bold;
        font-size: 15px;
    }
    .download-link{
        padding-bottom: 10px;
    }
    .download-link form label{
        font-weight: bold;
        color:gray;
        padding-bottom: 10px;
    }
    .footer-tel-email{
        display: flex;
        flex-direction: row;
        height: 60px;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
    .footer-tel{
        display: flex;
        align-items: center;
        width:50% ;
    }
    .footer-tel p span{
        font-weight: bold;
    }
    .footer-email{
        display: flex;
        align-items: center;
        width: 50%;
        height: 20px;
    }
    .footer-email p span{
        font-weight: bold;
    }
    .footer-socials{
        display: flex;
        width: 200px;
        justify-content: space-around;
    }
    .footer-socials img{
        cursor: pointer;
    }
    .footer-rights{
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mob-footer{
        display: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .navigation-bar{
        width: 100%;
        font-family: 'Roboto';
    }
    .navigation-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        height: 50px;
        border-bottom: 1px dotted;
    }
    .info{
        display: flex;
        width: 330px;
        justify-content: space-between;
        align-items: center;
        margin-left: 90px;
    }
    .advert{
        font-weight:bolder;
        background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Noto Sans JP", sans-serif;
        cursor: pointer;
    }
    .faq{
        min-width: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navigation-auth{
        padding: 20px 100px 20px 100px;
        display: flex;
        width: 100%;
        height: 130px;
        justify-content: flex-start;
    }
    .logo img{
        width: 150px;
    }
    .nav-search{
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 500px;
    }
    .nav-search-input{
        width: 350px;
    }
    .auth-wish-cart{
        display: flex;
        width: 600px;
        padding: 0px 0px 0px 20px;
        justify-content: space-evenly;
        
    }
    .auth-wish-cart img{
        width: 30px;
    }
    .auth-wish-cart a{
        text-decoration: none;
    }
    .auth{
        display: flex;
        flex-direction: column;
        color: gray;
    }
    .auth img{
        width: 30px;
    }
    .wish{
        color: gray;
    }
    .wish h3{
        text-decoration: none;
    }
    .cart{
        color: gray;
        text-decoration: none;
    }
    .cart h3{
        text-decoration: none;
    }
    .navigation-tabs{
        width: 100%;
        height: 40px;
        background-color: #ff6961;
        display: flex;
        flex-direction: row;
        transition: all linear 0.3s;
    }
    .navigation-tab{
        position: relative;
        margin-left: 400px;
        display: flex;
        width: auto;
    }
    .nav-tab{
        min-width: 100px;
        display: flex;
        padding: 0px 10px 0px 10px;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-weight: bold;
        color:white;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }
    .nav-tab a{
        color: white;
        text-decoration: none;
    }
    .special-nav-overlay{
        position: absolute;
        top:180px;
        left:895px;
        z-index: 3;
        background-color: var(--red-color);
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }
    .special-nav-tab{
        min-width: 300px;
        height: 100px;
        position: absolute;
        top: 140px;
        left: 840px;
        z-index: 2;
        border-radius: 2px;
        background-color: var(--light-red-color);
        animation: pulse 3s infinite; 
    }
    .special-nav-tab::before, .special-nav-tab::after{
        opacity: 0;
        animation: pulse 3s infinite;
        position: absolute;        
    }
    .special-nav-tab::before{
        background-color: var(--light-red-color);
        animation-delay: 2s;        
    }
    .special-nav-tab::after{
        background-color: var(--light-red-color);
        animation-delay: 2s;        
    }

    .special-nav-tab-2{
        min-width: 250px;
        height: 70px;
        position: absolute;
        top: 155px;
        left: 870px;
        z-index: 2;
        background-color: var(--light-red-color);
        animation: pulse 3s infinite; 
    }
    .special-nav-tab-2::before, .special-nav-tab-2::after{
        opacity: 0;
        animation: pulse 2s infinite;
        position: absolute;        
    }
    .special-nav-tab-2::before{
        background-color: var(--light-red-color);
        animation-delay: 0.5s;  
    }
    .special-nav-tab-2::after{
        background-color: var(--light-red-color);
        animation-delay: 0.5s;    
    }
    .animate-character{
        color: white;
        text-decoration: none;
        font-size: 17px;
    }
    .browse{
        background-color: var(--red-color);
        width: 270px;
        display: flex;
        padding: 10px 20px 10px 20px;
        color: white;
        justify-content: space-between;
        align-items: center ;
        transition: all linear 1s;
        height: 40px;
        font-weight: bold;
        font-size: 17px;
        cursor: pointer;
    }
    .navigation-dropdown{
        position: absolute;
        margin-left: 50px;
        cursor: pointer;
        color: #2c2c2c;
        transition: all linear 0.3s;
    }
    .navigation-dropdown-panel-div{
        display: flex;
        width: 400px;
        height: auto;
    }
    .navigation-dropdown-panel{
        height: auto;
        width: auto;
        position: relative;
        z-index: 3;
        background-color: white;
        padding: 5px;
        transition: all linear 2s;
    }
    .navigation-dropdown-child{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 15px;
        height: 45px;
        color: #54595F;
    }
    .navigation-dropdown-child:hover{
        background-color: whitesmoke;
    }
    .navigation-dropdown-child-img{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
    }
    .navigation-dropdown-child-img img{
        width: 25px;
        height: 25px;
    }
    .navigation-dropdown-child-p{
        color: black;
        width: 200px;
        padding-left: 20px;
        font-size: 15px;
    }
    .navigation-dropdown-child-icon{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #ff6961;
    }
    .navigation-side-panel-family-planning{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 265px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .family_planning_text{
        font-weight: bold;
        color: black;
    }
    .dropDownLinkPaper{
        height: 100px;
    }
    .dropDownLinkPaper-no-image{
        height: auto;
        width: auto;
        margin-left: 10px;
    }
    .dropDownLinkPaper-image{
        height: 100px;
        width: 100px;
    }
    .family_planning_products{
        height: 100%;
        color: var(--text-black-color);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .family_planning_products p{
        margin-left: 10px;
    }
    .family_planning_products:hover{
        background-color: whitesmoke;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px 10px 0px;
    }
    .navigation-side-panel-cosmetics{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -180px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-cough-flu{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 90px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-health-devices{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -360px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-supplements{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -270px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-anti-malaria{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 180px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-pain{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: 0px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .navigation-side-panel-stomach{
        width: 800px;
        height: 320px;
        background-color: white;
        position: relative;
        margin-top: -90px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }

    /* STICKY NAV  */
    .sticky-nav-browse{
        height: 100%;
        width: 200px;
    }
    .sticky-browse{
        height: 100%;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sticky-categories-dropdown-div{
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        position: absolute;
    }
    .sticky-navigation-panel{
        position: relative;
        height: auto;

    }
    .sticky-navigation-dropdown-child{
        background-color: white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 15px;
        height: 45px;
        width: auto;
        color: #54595F;
    }
    .sticky-navigation-dropdown-child:hover{
        background-color: whitesmoke;
    }
    .sticky-navigation-dropdown-child-img{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
    }
    .sticky-navigation-dropdown-child-img img{
        width: 25px;
        height: 25px;
    }
    .sticky-navigation-dropdown-child-p{
        color: black;
        width: 200px;
        padding-left: 20px;
        font-size: 15px;
    }
    .sticky-navigation-dropdown-child-icon{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #ff6961;
    }
    .sticky-navigation-side-panel-family-planning{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: 310px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .sticky-navigation-side-panel-cosmetics{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: -140px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .sticky-navigation-side-panel-cough-flu{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: 130px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .sticky-navigation-side-panel-health-devices{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: -320px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .sticky-navigation-side-panel-supplements{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: -230px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .sticky-navigation-side-panel-anti-malaria{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: 220px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .sticky-navigation-side-panel-pain{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: 40px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }
    .sticky-navigation-side-panel-stomach{
        width: 800px;
        height: 355px;
        background-color: white;
        position: relative;
        margin-top: -50px;
        margin-left: 30px;
        border-left: 1px dotted;
        padding: 10px 20px 10px 20px;
        overflow-y: scroll;
    }

    /* FOOTER */
    .partners{
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 0px;
    }
    .partner-logos{
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .partner-logos-img{
        width: 250px;
        height: 100px;
        object-fit: contain;
    }
    .footer-div{
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }
    .footer-tagline{
        background-color: var(--text-black-color);
        text-align: center;
        height: 330px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 10px 30px 10px;
        color: white;
    }
    .footer-tagline h2{
        word-spacing: 5px;
    }
    .footer-tags-div{
        padding: 30px 10px 50px 10px;
        height: auto;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-tags{
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex:1;
        width: auto;
    }
    .footer-tags p{
        font-size: 15px;
    }
    .footer-heat-feeder{
        height: 100px;
        display: flex;
        background-color:#EE1C22;
        color: white;
    }
    .productss{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .productss h1{
        font-weight: 1000;
    }
    .visitors{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
        border-right: 1px dotted;
    }
    .visitors h1{
        font-weight: 1000;
    }
    .deliveries{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;
        font-weight: bold;
    }
    .deliveries h1{
        font-weight: 1000;
    }
    .footer-nav{
        display: flex;
        padding-top: 10px;
        background-color: whitesmoke;
        height: 200px;
    }
    .footer-nav-title{
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 15px;
    }
    .know-us{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .know-us ul{
        list-style-type: none;
    }
    .know-us ul li a{
        text-decoration: none;
    }
    .policies{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .policies ul{
        list-style-type: none;
    }
    .policies ul li a{
        text-decoration: none;
    }
    .services{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
    }
    .services ul{
        list-style-type: none;
    }
    .services ul li a{
        text-decoration: none;
    }
    .connect{
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .connect p{
        font-weight: bold;
        font-size: 15px;
    }
    .download-link{
        padding-bottom: 10px;
    }
    .download-link form label{
        font-weight: bold;
        color:gray;
        padding-bottom: 10px;
    }
    .footer-tel-email{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    .footer-tel{
        display: flex;
        align-items: center;
    }
    .footer-tel p span{
        font-weight: bold;
    }
    .footer-email{
        display: flex;
        align-items: center;
        height: 20px;
    }
    .footer-email p span{
        font-weight: bold;
    }
    .footer-socials{
        display: flex;
        width: 200px;
        justify-content: space-around;
    }
    .footer-socials img{
        cursor: pointer;
    }
    .footer-rights{
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
} 
