/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .notFound{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ff6961;
        height: 100vh;
        /* margin-top: 100px; */
        text-align: center;
    }
    .notFound h3{
        margin-top: 10px;
    }
    .notFound p{
        /* width: 200px; */
    }
    .notFoundPaper{
        width: 350px;
        text-align: center;
        padding: 10px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .notFound{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ff6961;
        height: 100vh;
        /* margin-top: 100px; */
        text-align: center;
    }
    .notFound h3{
        margin-top: 10px;
    }
    .notFound p{
        /* width: 200px; */
    }
    .notFoundPaper{
        width: 350px;
        text-align: center;
        padding: 10px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .notFound{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ff6961;
        height: 100vh;
        /* margin-top: 100px; */
        text-align: center;
    }
    .notFound h3{
        margin-top: 10px;
    }
    .notFound p{
        /* width: 200px; */
    }
    .notFoundPaper{
        width: 350px;
        text-align: center;
        padding: 10px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .notFound{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ff6961;
        height: 100vh;
        /* margin-top: 100px; */
        text-align: center;
    }
    .notFound h3{
        margin-top: 10px;
    }
    .notFound p{
        /* width: 200px; */
    }
    .notFoundPaper{
        width: 350px;
        text-align: center;
        padding: 10px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .notFound{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ff6961;
        height: 100vh;
        /* margin-top: 100px; */
        text-align: center;
    }
    .notFound h3{
        margin-top: 10px;
    }
    .notFound p{
        /* width: 200px; */
    }
    .notFoundPaper{
        width: 350px;
        text-align: center;
        padding: 10px;
    }
}