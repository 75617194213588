:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}

.blog-posted-by{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.blog-posted-by-detail{
    width: 150px;
    color: var(--text-black-color);
}
.blog-posted-by-name{
    margin-left: 10px;
    margin-top: -15px;
    font-size: 12px;
}
.blog-detail-wrapper{
    padding:10px 20px 10px 20px;
    display: flex;
    flex-direction: column;
}
.blog-detail-date{
    /* background-color: coral; */
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    color: var(--text-black-color);
}
.blog-detail-title{
    padding-bottom: 20px;
}
.blog-details-comments{
    /* padding: 30px; */
    background-color: whitesmoke;
    margin-top: 30px;
    border-radius: 20px;
}
.blog-detail-img{
    width: 400px;
    height: 400px;
    margin-bottom: 20px ;
}
.blog-detail-img img{
    width: 100%;
    height: 100%;
}
.blog-add-wrapper{
    padding: 30px;
}
.blog-add-comment-div{
    display: flex;
    flex-direction: column;
}
.blog-add-comment-div p{
    color: var(--red-color);
}
.blog-add-div{
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}
.blog-add-comment-btn{
    width: 200px;
    margin-right: 0;
}
.blog-details-card{
    padding: 10px;
}
.comments{
    height: 600px;
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
}
.comment-card{
    margin-top: 30px;
    padding: 10px;
}
.comment-card-icon-div{
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: whitesmoke;
    padding: 5px 5px 5px 5px;
}
.comment-name-icon p{
    font-weight: bold;
    color: var(--text-black-color);
}
.comment-card-icon{
    color: gray;
    margin-right: 10px;
    font-size: 50px;
}
.comment-card-text{
    padding-top: 20px;
    padding-left: 20px;
}
.comment-edit{
    height: 100%;
    display: flex;
}
.comment-edit-div{
    display: flex;
    width: 100%;
    padding-bottom: 5px;
    justify-content: space-between;
}