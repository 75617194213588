:root{
    --red-color:#EE1C22;
    --text-black-color:#36454F;
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .login-div{
        height: 100vh;
        width: 100%;
        background-color: whitesmoke;
    }
    .back-to-home{
        display: none;
    }
    .login-mob-back-to-home{
        display: flex;
        height: 50px;
        width: 100%;
        padding-left: 20px;  
    }
    .login-mob-back-btn{
        height: 25px;
        width: 80px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: var(--text-black-color);
        border: var(--text-black-color) solid 1px;
        border-radius: 15px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .login-highlight{
        width: 100%;
        min-height: 150px;
        height: auto;
        background-color: whitesmoke;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
    .login-highlight img{
        height: 70px;
        width: 300px;
    }
    .login-highlight h3{
        margin-top: 30px;
    }
    .login-heading{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .login-heading p{
        padding: 10px;
        text-align: center;
        color: gray;
    }
    .login-form{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        padding: 10px;
    }
    .login-form input{
        margin-top: 10px;
    }
    .login-forgot-pass{
        font-size: small;
        display: flex;
        justify-content: flex-end;
    }
    .login-forgot-pass a{
        color: blue;
        text-decoration: none;
    }
    .login-forgot-pass a:hover{
        color: var(--red-color);
        text-decoration: underline;
    }
    .login-paper{
        width: 330px;
        margin-top: 10px;
    }
    .login-paper-div{
        display: flex;
        justify-content: center;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }
    .login-terms{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        padding: 10px
    }
    .login-social{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: gray;
    }
    #login-social-btn{
        margin-top: 10px;
        width: 300px;
        justify-content: center;
        color: black;
    }
    #login-social-btn img{
        margin-right: 15px;
    }
    .no-account{
        height: 40px;
        margin-top: 30px;
    }
    .login-contact{
        height: 100px;
        width: 350px;
        text-align: center;
        font-weight: bold;
    }
    .login-progress{
        display: flex;
        justify-content: center;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .login-div{
        height: 100vh;
        width: 100%;
        background-color: whitesmoke;
    }
    .back-to-home{
        display: none;
    }
    .login-mob-back-to-home{
        display: flex;
        height: 50px;
        width: 100%;
        padding-left: 20px;  
    }
    .login-mob-back-btn{
        height: 25px;
        width: 80px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: var(--text-black-color);
        border: var(--text-black-color) solid 1px;
        border-radius: 15px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .login-highlight{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
    .login-highlight img{
        height: 70px;
        width: 300px;
    }
    .login-highlight h3{
        margin-top: 30px;
    }
    .login-heading{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .login-heading p{
        padding: 10px;
        text-align: center;
        color: gray;
    }
    .login-form{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        padding: 10px;
    }
    .login-form input{
        margin-top: 10px;
    }
    .login-forgot-pass{
        font-size: small;
        display: flex;
        justify-content: flex-end;
    }
    .login-forgot-pass a{
        color: blue;
        text-decoration: none;
    }
    .login-forgot-pass a:hover{
        color: var(--red-color);
        text-decoration: underline;
    }
    .login-paper{
        width: 330px;
        margin-top: 10px;
    }
    .login-paper-div{
        display: flex;
        justify-content: center;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }
    .login-terms{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        padding: 10px
    }
    .login-social{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: gray;
    }
    #login-social-btn{
        margin-top: 10px;
        width: 300px;
        justify-content: flex-start;
        color: black;
    }
    #login-social-btn img{
        margin-right: 15px;
    }
    .no-account{
        height: 40px;
        margin-top: 30px;
    }
    .login-contact{
        height: 100px;
        width: 350px;
        text-align: center;
        font-weight: bold;
    }
    .login-progress{
        display: flex;
        justify-content: center;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .login-div{
        height: 100vh;
        width: 100%;
        background-color: whitesmoke;
    }
    .back-to-home{
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-bottom: 20px;
    }
    .back-to-home a{
        width: 140px;
        height: 30px;
        background-color: none;
        color: var(--text-black-color);
        border: solid 1px;
        border-radius: 20px;
        display: flex;
        text-decoration: none;
        justify-content: space-around;
        align-items: center;
        padding: 0px 5px 0px 5px;
    }
    .login-mob-back-to-home{
        display: none;
    }
    .login-highlight{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
    .login-highlight img{
        height: 70px;
        width: 300px;
    }
    .login-highlight h3{
        margin-top: 30px;
    }
    .login-heading{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .login-heading p{
        padding: 10px;
        text-align: center;
        color: gray;
    }
    .login-form{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        padding: 10px;
    }
    .login-form input{
        margin-top: 10px;
    }
    .login-forgot-pass{
        font-size: small;
        display: flex;
        justify-content: flex-end;
    }
    .login-forgot-pass a{
        color: blue;
        text-decoration: none;
    }
    .login-forgot-pass a:hover{
        color: var(--red-color);
        text-decoration: underline;
    }
    .login-paper{
        width: 330px;
        margin-top: 10px;
    }
    .login-paper-div{
        display: flex;
        justify-content: center;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }
    .login-terms{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        padding: 10px
    }
    .login-social{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: gray;
    }
    #login-social-btn{
        margin-top: 10px;
        width: 300px;
        justify-content: center;
        color: black;
    }
    #login-social-btn img{
        margin-right: 15px;
    }
    .no-account{
        height: 40px;
        margin-top: 30px;
    }
    .login-contact{
        height: 100px;
        width: 350px;
        text-align: center;
        font-weight: bold;
    }
    .login-progress{
        display: flex;
        justify-content: center;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .back-to-home{
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 100px;
    }
    .back-to-home a{
        width: 140px;
        height: 30px;
        background-color: none;
        color: var(--text-black-color);
        border: solid 1px;
        border-radius: 20px;
        display: flex;
        text-decoration: none;
        justify-content: space-around;
        align-items: center;
        padding: 0px 5px 0px 5px;
    }
    .login-mob-back-to-home{
        display: none;
    }
    .login-div{
        height: 100vh;
        width: 100%;
        background-color: whitesmoke;
    }
    .login-highlight{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }
    .login-highlight img{
        height: 70px;
        width: 300px;
    }
    .login-highlight h3{
        margin-top: 30px;
    }
    .login-heading{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .login-heading p{
        padding: 10px;
        text-align: center;
        color: gray;
    }
    .login-form{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        padding: 10px;
    }
    .login-form input{
        margin-top: 10px;
    }
    .login-forgot-pass{
        font-size: small;
        display: flex;
        justify-content: flex-end;
    }
    .login-forgot-pass a{
        color: blue;
        text-decoration: none;
    }
    .login-forgot-pass a:hover{
        color: var(--red-color);
        text-decoration: underline;
    }
    .login-paper{
        width: 400px;
        margin-top: 10px;
    }
    .login-paper-div{
        display: flex;
        justify-content: center;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }
    .login-terms{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        padding: 10px
    }
    .login-social{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: gray;
    }
    #login-social-btn{
        margin-top: 10px;
        width: 300px;
        justify-content: center;
        color: black;
    }
    #login-social-btn img{
        margin-right: 15px;
    }
    .login-contact{
        height: 100px;
        width: 350px;
        padding-top: 20px;
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
    }
    .login-progress{
        display: flex;
        justify-content: center;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .login-div{
        min-height: 100vh;
        height: auto;
        width: 100%;
        background-color: whitesmoke;
    }
    .back-to-home{
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 100px;
    }
    .back-to-home a{
        width: 140px;
        height: 30px;
        background-color: none;
        color: var(--text-black-color);
        border-radius: 20px;
        display: flex;
        text-decoration: none;
        justify-content: space-around;
        align-items: center;
        padding: 0px 5px 0px 5px;
    }
    .login-mob-back-to-home{
        display: none;
    }
    .login-highlight{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;

    }
    .login-highlight img{
        height: 70px;
        width: 300px;
    }
    .login-highlight h3{
        margin-top: 30px;
    }
    .login-heading{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .login-heading p{
        padding: 10px;
        text-align: center;
        color: gray;
    }
    .login-form{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        padding: 10px;
    }
    .login-form input{
        margin-top: 10px;
    }
    .login-forgot-pass{
        font-size: small;
        display: flex;
        justify-content: flex-end;
    }
    .login-forgot-pass a{
        color: blue;
        text-decoration: none;
    }
    .login-forgot-pass a:hover{
        color: var(--red-color);
        text-decoration: underline;
    }
    .login-paper{
        width: 400px;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .login-paper-div{
        display: flex;
        justify-content: center;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }
    .login-terms{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        padding: 10px
    }
    .login-social{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: gray;
    }
    #login-social-btn{
        margin-top: 10px;
        width: 300px;
        justify-content: center;
        color: black;
    }
    #login-social-btn img{
        margin-right: 15px;
    }
    .no-account{
        margin-top: 30px;
    }
    .login-contact{
        height: 100px;
        width: 350px;
        text-align: center;
        font-weight: bold;
    }
    .login-progress{
        display: flex;
        justify-content: center;
    }
 } 