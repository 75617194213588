
:root{
  --red-color:#EE1C22;
  --light-red-color:#ff6961;
  --teal-color: #96D2C8;
  --text-black-color:#36454F;
}
@keyframes filterOptionsWidthGrow{
  from {
    width: 0px;
    display: none;
  }
  to {
    width: 200px;
    display: block;
  }
}
@keyframes filterOptionsWidthShrink{
  from { 
    width: 200px;
    display: block; 
  }
  to { 
    width: 0px;
    display: none; 
  }
}


@media only screen and (max-width: 600px) {
  .family-planning-wrapper{
    display: flex;
    flex-direction: column;
  }
  .family-planning-filter{
    display: none;
  }
  .family-planning-filter-mob{
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: whitesmoke;
  }
  .family-planning-filter-results-mob-hidden{
    height: 0px;
    display: none;
    transition: display 1s ease;
    transition: height 1s ease;
    padding: 10px;
  }
  .family-planning-filter-results-mob-show{
    height: auto;
    display: block;
    transition: display 1s ease;
    transition: height 1s ease;
    padding: 10px;
  }
  .family-planning-filter-mob-label{
    padding: 10px;
    background-color: white;
  }
  .family-planning-filter-div{
    padding: 10px;
  }
  .filter-chips{
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .fp-filter{
    background-color: #0066b2;
    position: absolute;
    top:650px;
    right: 0;
    height: 40px;
    width: auto;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .filter-options-closed{
    width: 0px;
    height: 0px;
    display: none;
    animation-name: filterOptionsWidthShrink;
    animation-duration: 1s;
  }
  .filter-options-open{
    width: 200px;
    height: auto;
    min-height: 200px;
    display: block;
    background-color: whitesmoke;
    animation-name: filterOptionsWidthGrow;
    animation-duration: 1s;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: var(--text-black-color);
  }
  .filter-brand{
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .filter-text{
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 5px;
    font-size: 15px;
  }
  .fp-filter-icon{
    color: white;
  }
}

@media only screen and (min-width: 600px) {
  .family-planning-wrapper{
    display: flex;
    flex-direction: column;
  }
  .family-planning-filter{
    display: none;
  }
  .family-planning-filter-mob{
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: whitesmoke;
  }
  .family-planning-filter-results-mob-hidden{
    height: 0px;
    display: none;
    transition: display 1s ease;
    transition: height 1s ease;
    padding: 10px;
  }
  .family-planning-filter-results-mob-show{
    height: auto;
    display: block;
    transition: display 1s ease;
    transition: height 1s ease;
    padding: 10px;
  }
  .family-planning-filter-mob-label{
    padding: 10px;
  }
  .family-planning-filter-mob-accordion{
    width: 300px;
  }
  .family-planning-filter-div{
    padding: 10px;
  }
  .filter-chips{
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .fp-filter{
    background-color: #0066b2;
    position: absolute;
    top:650px;
    right: 0;
    height: 40px;
    width: auto;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .filter-options-closed{
    width: 0px;
    height: 0px;
    display: none;
    animation-name: filterOptionsWidthShrink;
    animation-duration: 1s;
  }
  .filter-options-open{
    width: 200px;
    height: auto;
    min-height: 200px;
    display: block;
    background-color: whitesmoke;
    animation-name: filterOptionsWidthGrow;
    animation-duration: 1s;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: var(--text-black-color);
  }
  .filter-brand{
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .filter-text{
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 5px;
    font-size: 15px;
  }
  .fp-filter-icon{
    color: white;
  }
}

@media only screen and (min-width: 768px) {
  .family-planning-wrapper{
    display: flex;
    flex-direction: column;
  }
  .family-planning-filter{
    display: none;
  }
  .family-planning-filter-mob{
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: whitesmoke;
  }
  .family-planning-filter-results-mob-hidden{
    height: 0px;
    display: none;
    transition: display 1s ease;
    transition: height 1s ease;
    padding: 10px;
  }
  .family-planning-filter-results-mob-show{
    height: auto;
    display: block;
    transition: display 1s ease;
    transition: height 1s ease;
    padding: 10px;
  }
  .family-planning-filter-mob-label{
    padding: 10px;
  }
  .family-planning-filter-mob-accordion{
    width: 300px;
  }
  .family-planning-filter-div{
    padding: 10px;
  }
  .filter-chips{
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .fp-filter{
    background-color: #0066b2;
    position: absolute;
    top:650px;
    right: 0;
    height: 40px;
    width: auto;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .filter-options-closed{
    width: 0px;
    height: 0px;
    display: none;
    animation-name: filterOptionsWidthShrink;
    animation-duration: 1s;
  }
  .filter-options-open{
    width: 200px;
    height: auto;
    min-height: 200px;
    display: block;
    background-color: whitesmoke;
    animation-name: filterOptionsWidthGrow;
    animation-duration: 1s;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: var(--text-black-color);
  }
  .filter-brand{
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .filter-text{
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 5px;
    font-size: 15px;
  }
  .fp-filter-icon{
    color: white;
  }
  .product-grid-div{
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
} 

@media only screen and (min-width: 992px) {
  .family-planning-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--text-black-color);
  }
  .family-planning-carousel{
    display:'flex';
    flex-direction: row;
  }
  .family-planning-filter{
    width: 15%;
    padding: 10px;
    display: block;
  }
  .family-planning-filter-mob{
    display: none;
  }
  .family-planning-filter-label{
    display: flex;
    justify-content: flex-start;
    height: 50px;
    align-items: center;
  }
  .family-planning-filter-label h5{
    padding-left: 10px;
  }
  .family-planning-div{
    width: 85%;
  }
  .family-planning-option{
    cursor: pointer;
    padding: 5px;
  }
  .family-planning-option:hover{
    background-color: whitesmoke;
  }
  .family-planning-filter-div{
    padding: 20px 10px 10px 10px;
  
  }
  .filter-chips{
    padding-left: 30px;
    padding-bottom: 20px;
  }
  .family-planning-loading{
    margin-top: 50px;
    margin-left: 40%;
  }
  .fp-filter{
    background-color: #0066b2;
    position: absolute;
    top:650px;
    right: 0;
    height: 40px;
    width: auto;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .filter-options-closed{
    width: 0px;
    height: 0px;
    display: none;
    animation-name: filterOptionsWidthShrink;
    animation-duration: 1s;
  }
  .filter-options-open{
    width: 200px;
    height: auto;
    min-height: 200px;
    display: block;
    background-color: whitesmoke;
    animation-name: filterOptionsWidthGrow;
    animation-duration: 1s;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: var(--text-black-color);
  }
  .filter-brand{
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .filter-text{
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 5px;
    font-size: 15px;
  }
  .fp-filter-icon{
    color: white;
  }
}

@media only screen and (min-width: 1200px) {
  .family-planning-wrapper{
    display: flex;
    width: 100%;
    color: var(--text-black-color);
  }
  .family-planning-carousel{
    display:'flex';
    flex-direction: row;
  }
  .fp-filter{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 750px;
    z-index: 5;
    width: 50px;
    height: 50px;
    margin-left: auto;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: coral;
  }
  .family-planning-filter{
    width: 15%;
    padding: 10px;
  }
  .family-planning-filter-mob{
    display: none;
  }
  .family-planning-filter-label{
    display: flex;
    justify-content: flex-start;
    height: 50px;
    align-items: center;
  }
  .family-planning-filter-label h5{
    padding-left: 10px;
  }
  .family-planning-div{
    width: 100%;
  }
  .product-grid-div{
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .family-planning-option{
    cursor: pointer;
    padding: 5px;
  }
  .family-planning-option:hover{
    background-color: whitesmoke;
  }
  .family-planning-filter-div{
    padding: 20px 10px 10px 10px;
  
  }
  .filter-chips{
    padding-left: 30px;
    padding-bottom: 20px;
  }
  .family-planning-loading{
    margin-top: 50px;
    margin-left: 40%;
  }
  .fp-filter{
    background-color: #0066b2;
    color: white;
    position: absolute;
    top:750px;
    right: 0;
    height: 50px;
    width: auto;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .filter-options-closed{
    width: 0px;
    height: 0px;
    display: none;
    animation-name: filterOptionsWidthShrink;
    animation-duration: 1s;
  }
  .filter-options-open{
    width: 200px;
    height: auto;
    min-height: 200px;
    display: block;
    background-color: whitesmoke;
    animation-name: filterOptionsWidthGrow;
    animation-duration: 1s;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: var(--text-black-color);
  }
  .filter-brand{
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .filter-text{
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 5px;
    font-size: 15px;
  }
  .fp-filter-icon{
    color: white;
  }
}