.featuredP-card{
    height: 200px;
    width: 300px;
    margin-bottom: 20px;
    margin-left: 15%;
    padding: 0;
}
.featuredP-card:hover{
    border: 1px thin black;
    cursor: pointer;
}
.featuredP-card img{
    width: inherit;
    height: inherit;
    object-fit: contain;
}