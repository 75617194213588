 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .wishlist-div{
    }
    .wishlist-remove{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .wishlist-grid{
        margin-bottom:'20px'
    }
    .wishlist-no-items{
        /* background-color: coral; */
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {}
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {}
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .wishlist-no-items{
        height: 100vh;
        width: 100vw;
        margin-top: 17px;
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .wishlist-div{
        margin-top: 15px;
        width: 100%;

    }
    .wishlist-remove{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .wishlist-no-items{
        height: 100vh;
        width: 100vw;
        margin-top: 17px;
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
 }