:root{
    --red-color:#EE1C22;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}
 
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .home-wrapper{
        width: 100vw;
        height: auto;
        background-color: whitesmoke;
    }
    .mob-search-comp-div{
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: white;
        padding: 0px 10px 0px 10px;

    }
    .mob-search-comp-button{
        border-radius: 25px;
        width: 320px;
        border-color: gray;
        color: lightslategray;
        background-color: whitesmoke;
        padding: 5px;
    }
    .mob-search-input{
        border-radius: 50%;
    }
    .mob-spec-navigation{
        display: flex;
        padding: 10px 10px 10px 10px;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }
    .mob-spec-navigation a{
        text-decoration: none;
    }
    .mob-navigation{
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right:10px;
    }
    .mob-navigation p{
        color: white;
    }
    .mob-navigation-1{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right:10px;
    }
    .mob-navigation-1 p {
        color: white;
    }
    .mob-navigation-2{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right:10px;
    }
    .mob-navigation-2 p {
        color: white;
    }
    .mob-navigation-3{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right:10px;
    }
    .mob-navigation-3 p {
        color: white;
    }
    .mob-navigation-4{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right:10px;
    }
    .mob-navigation-4 p {
        color: white;
    }
    .mob-navigation-5{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right:10px;
    }
    .mob-navigation-5 p {
        color: white;
    }
    .mb-popular-cat-label{
        display: block;
    }
    .circular-progress{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: whitesmoke;
        padding-top: 100px;
        width: 100%;
        height: 100vh;

    }
    .highlight-div{
        display: none;
    }
    .mob-highlight-div{
        margin-top: 20px;
        padding: 10px;
        border-radius: 5px;
    }
    .tab-highlight-div{
        display: none;
    }
    .carousel-img{
        background-color: whitesmoke;
        width: 100%;
        height: 200px;
        object-fit: fill;
        border-radius: 5px;
    }
    .sticky-nav{
        display: none;
    }
    .sticky-nav.fixed{
        display: none;
    }
    .home-body{
        display: flex;
        flex-direction: column;
        padding-top:30px;
        padding-bottom: 30px;
        background-color: whitesmoke;

    }
    .mob-welcome{
        background-color: white;
        padding: 20px 10px 10px 10px;
        text-align: center;
    }
    .mob-welcome p span{
        font-weight: bold;
        color: lightslategray;
    }

    .discount{
        display: none;
    }
    .progress{
        margin-top: 30px;
    }
    .carousel-no-images{
        height:15em;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .sticky-talk-to-doc{
        display: flex;
        justify-content: flex-end;
        align-items:end;
        pointer-events: auto;
        height: 100px;
        width: 100%;
        background-color: none;
        padding: 0;
        z-index: 10000;
        position: fixed;
        top: 450px;
        left: 0;
        padding-bottom: 5px;
        padding-right: 20px;
    }
    .talk-to-doc-open{
        width: 150px;
        height: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 1px  black;
        color:white;
        font-weight: normal;
        cursor: pointer;
        padding: 5px;
        text-align: center;
    }
    .talk-to-doc-closed{
        display: none;
    }
    .close-talk-to-doc{
        height: auto;
        width: auto;
        padding: 0;
        padding-right: 2px;
        padding-bottom: 2px;
        position: fixed;
        color: #4158D0;
        font-size: small;
        cursor: pointer;
    }
    .close-talk-to-doc-off{
        display: none;
    }
    .talk-to-doc-toggle{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        border-radius: 5px;
    }
    .talk-to-doc-toggle-off{
        display: none;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .home-wrapper{
        width: 100vw;
        height: auto;
        background-color: whitesmoke;
    }
    .search-comp-div{
        position: absolute;
        z-index: 2;
        height: 70px;
        width: 100%;
        background-color: white;
        padding: 0px 10px 0px 10px;
    }
    .mob-spec-navigation{
        display: flex;
        padding: 10px 10px 10px 10px;
        margin-top: 80px;
        justify-content: space-around;
    }
    .mob-spec-navigation a{
        text-decoration: none;
    }
    .mob-navigation{
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);        
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mob-navigation p{
        color: white;
    }
    .mob-navigation-1{
        background-color: #FF9A8B;
        background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-1 p {
        color: white;
    }
    .mob-navigation-2{
        background-color: #FFE53B;
        background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-2 p {
        color: white;
    }
    .mob-navigation-3{
        background-color: #4158D0;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-3 p {
        color: white;
    }
    .mob-navigation-4{
        background-color: #08AEEA;
        background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
            
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-4 p {
        color: white;
    }
    .mob-navigation-5{
        background-color: #8BC6EC;
        background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
        padding: 0px 5px 0px 5px;                     
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-5 p {
        color: white;
    }
    .carousel-img{
        background-color: whitesmoke;
        width: 100%;
        height: 100px;
        object-fit: cover;
    }
    .home-body{
        display: flex;
        flex-direction: column;
        padding-top:30px;
        padding-bottom: 30px;
        background-color: whitesmoke;

    }
    .mob-welcome{
        background-color: white;
        padding: 30px 10px 20px 10px;
        text-align: center;
    }
    .highlight-div{
        display: none;
    }
    .mob-highlight-div{
        margin-top: 20px;
        padding: 10px;
        border-radius: 5px;
    }
    .tab-highlight-div{
        display: none;
    }
    .discount{
        display: none;
    }
    .carousel-img{
        background-color: whitesmoke;
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 5px;
    }
    .progress{
        margin-top: 30px;
    }
    .carousel-no-images{
        height:15em;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .sticky-talk-to-doc{
        display: flex;
        justify-content: flex-end;
        align-items:end;
        pointer-events: auto;
        height: 100px;
        width: 100%;
        background-color: none;
        padding: 0;
        z-index: 10000;
        position: fixed;
        top: 450px;
        left: 0;
        padding-bottom: 5px;
        padding-right: 20px;
    }
    .talk-to-doc-open{
        width: 150px;
        height: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 1px  black;
        color:white;
        font-weight: normal;
        cursor: pointer;
        padding: 5px;
        text-align: center;
    }
    .talk-to-doc-closed{
        display: none;
    }
    .close-talk-to-doc{
        height: auto;
        width: auto;
        padding: 0;
        padding-right: 2px;
        padding-bottom: 2px;
        position: fixed;
        color: #4158D0;
        font-size: small;
        cursor: pointer;
    }
    .close-talk-to-doc-off{
        display: none;
    }
    .talk-to-doc-toggle{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        border-radius: 5px;
    }
    .talk-to-doc-toggle-off{
        display: none;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .home-wrapper{
        width: 100vw;
        height: auto;
        background-color: whitesmoke;
    }
    .search-comp-div{
        position: absolute;
        z-index: 2;
        height: 70px;
        width: 100%;
        background-color: white;
        padding: 0px 10px 0px 10px;
    }
    .mob-search-comp-div{
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: white;
        padding: 0px 10px 0px 10px;

    }
    .mob-search-comp-button{
        border-radius: 25px;
        width: 250px;
        border-color: gray;
        color: lightslategray;
    }
    .mob-spec-navigation{
        display: flex;
        padding: 5px 10px 10px 10px;
        margin-top:40px;
        justify-content: space-around;
    }
    .mob-spec-navigation a{
        text-decoration: none;
    }
    .mob-navigation{
        background-color: #0093E9;
        background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);        
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mob-navigation p{
        color: white;
    }
    .mob-navigation-1{
        background-color: #FF9A8B;
        background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-1 p {
        color: white;
    }
    .mob-navigation-2{
        background-color: #FFE53B;
        background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-2 p {
        color: white;
    }
    .mob-navigation-3{
        background-color: #4158D0;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-3 p {
        color: white;
    }
    .mob-navigation-4{
        background-color: #08AEEA;
        background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
            
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-4 p {
        color: white;
    }
    .mob-navigation-5{
        background-color: #8BC6EC;
        background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
        padding: 0px 5px 0px 5px;                     
        width: 85px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .mob-navigation-5 p {
        color: white;
    }
    .circular-progress{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: whitesmoke;
        padding-top: 100px;
        width: 100%;
        height: 100vh;

    }
    .carousel-img{
        background-color: whitesmoke;
        width: 100%;
    }
    .sticky-nav{
        display: none;
    }
    .sticky-nav.fixed{
        display: none;
    }
    .home-body{
        display: flex;
        flex-direction: column;
        padding-top:30px;
        padding-bottom: 30px;
        background-color: whitesmoke;

    }
    .mob-welcome{
        background-color: white;
        padding: 20px 10px 20px 10px;
        text-align: center;
    }
    .highlight-div{
        display: none;
    }
    .mob-highlight-div{
        display: none;
    }
    .tab-highlight-div{
        display: block;
        margin-top: 40px;
        margin-bottom: 30px;
        padding: 10px;
        height: 300px;
    }
    .discount{
        display: none;
    }
    .carousel-img{
        background-color: whitesmoke;
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 5px;
    }
    .progress{
        margin-top: 30px;
    }
    .carousel-no-images{
        height:22em;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .sticky-talk-to-doc{
        display: flex;
        justify-content: flex-end;
        align-items:end;
        pointer-events: auto;
        height: 100px;
        width: 100%;
        background-color: none;
        padding: 0;
        z-index: 10000;
        position: fixed;
        top: 450px;
        left: 0;
        padding-bottom: 5px;
        padding-right: 20px;
    }
    .talk-to-doc-open{
        width: 150px;
        height: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 1px  black;
        color:white;
        font-weight: normal;
        cursor: pointer;
        padding: 5px;
        text-align: center;
    }
    .talk-to-doc-closed{
        display: none;
    }
    .close-talk-to-doc{
        height: auto;
        width: auto;
        padding: 0;
        padding-right: 2px;
        padding-bottom: 2px;
        position: fixed;
        color: #4158D0;
        font-size: small;
        cursor: pointer;
    }
    .close-talk-to-doc-off{
        display: none;
    }
    .talk-to-doc-toggle{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        border-radius: 5px;
    }
    .talk-to-doc-toggle-off{
        display: none;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .mob-welcome{
        display: none;
    }
    .mob-spec-navigation{
        display: none;
    }
    .mob-search-comp-div{
        display: none;
    }
    .mb-popular-cat-label{
        display: none;
    }
    .home-wrapper{
        width: 100vw;
        padding: 0px;
        font-family: 'Roboto', sans-serif;
        height: auto;
    }
    .search-comp-div{
        position: absolute;
        height: 70px;
        width: 100%;
        padding: 0px 100px 0px 100px;
        background-color: white;
    }
    .mob-welcome{
        display: none;
    }
    .mob-spec-navigation{
        display: none;
    }
    .home-carousel{
        padding: 0;
    }
    .circular-progress{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: whitesmoke;
        padding-top: 100px;
        width: 100%;
        height: 100vh;

    }
    .carousel-img{
        background-color: whitesmoke;
        width: 100%;
        height: 250px;
        object-fit: contain;
        border-radius: 0px;
    }
    .carousel-paper{
        height: 100%;

    }
    .discount{
        display: block;
        height: 200px;
        width: 30%;
    }
    .discount img{
        object-fit: cover;
    }
    .sticky-nav{
        display: block;
        pointer-events: none;
        height: 50px;
        width: 100%;
        background-color: none;
        padding: 0;
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }
    .sticky-nav.fixed {
        display: block;
        visibility: visible;
        background-color: whitesmoke;
        top: 0;
        left: 0;
        padding: 0;
        height: 50px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        opacity: 1;
        pointer-events: auto;
    }
    .sticky-nav-paper{
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: var(--text-black-color);
    }
    .sticky-nav-logo{
        color: var(--text-black-color);
    }
    .sticky-nav-logo a{
        text-decoration: none;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 450px;
        height: 100%;
    }
    .sticky-nav-navigation-in{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 600px;
        height: 100%;
    }
    .sticky-nav-navigation-opt-auth{
        display: flex;
        width:150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        border-left: 1px dotted var(--text-black-color);
        border-right: 1px dotted var(--text-black-color);
    }
    .sticky-nav-navigation-opt-auth a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-auth a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-auth a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-cart{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);
    }
    .sticky-nav-navigation-opt-cart a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-cart a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-cart a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-orders{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);
    }
    .sticky-nav-navigation-opt-orders a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-orders a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-orders a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-wishlist{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);;
    }
    .sticky-nav-navigation-opt-wishlist a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-wishlist a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-wishlist a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-logout{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);;
    }
    .sticky-nav-navigation-opt-logout a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-logout a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-search{
        width: 250px;
        height: 35px;
        border-radius: 15px;
        background-color: whitesmoke;
        border: 1pxgray solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-body{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        padding-bottom: 30px;
        overflow: hidden;
    }
    .carousel-no-images{
        height:20em;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .highlight-div{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1; 
        top: 0;
        padding: 0;
        display: flex;
        border-radius: 0px;
        margin-top: 0;
    }
    .mob-highlight-div{
        display: none;
    }
    .tab-highlight-div{
        display: none;
    }
    .sticky-talk-to-doc{
        display: flex;
        justify-content: flex-end;
        align-items:end;
        pointer-events: auto;
        height: 100px;
        width: 100%;
        background-color: none;
        padding: 0;
        z-index: 10000;
        position: fixed;
        top: 550px;
        left: 0;
        padding-bottom: 5px;
        padding-right: 10px;
    }
    .talk-to-doc-open{
        width: 150px;
        height: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 1px  black;
        color:white;
        font-weight: normal;
        cursor: pointer;
        padding: 5px;
        text-align: center;
    }
    .talk-to-doc-closed{
        display: none;
    }
    .close-talk-to-doc{
        height: auto;
        width: auto;
        padding: 0;
        padding-right: 2px;
        padding-bottom: 2px;
        position: fixed;
        color: #4158D0;
        font-size: small;
        cursor: pointer;
    }
    .close-talk-to-doc-off{
        display: none;
    }
    .talk-to-doc-toggle{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        border-radius: 5px;
    }
    .talk-to-doc-toggle-off{
        display: none;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .home-wrapper{
        width: 100vw;
        padding: 0;
        /* position: relative; */
        font-family: 'Roboto', sans-serif;
        height: auto;
    }
    .search-comp-div{
        position: absolute;
        z-index: 2;
        height: 70px;
        width: 100%;
        background-color: white;
    }
    .carousel-div{
        position: relative;
        height: 300px;
        margin-top: 5px;
        max-width: 100%;
        box-sizing: border-box;
        margin-bottom: 12px;
    }
    .mob-welcome{
        display: none;
    }
    .mob-spec-navigation{
        display: none;
    }
    .mob-search-comp-div{
        display: none;
    }
    .mb-popular-cat-label{
        display: none;
    }
    .highlight-div{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1; 
        top: 0;
        padding: 0;
        display: flex;
        border-radius: 0px;
        margin-top: 0;
    }
    .mob-highlight-div{
        display: none;
    }
    .tab-highlight-div{
        display: none;
    }
    .circular-progress{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: whitesmoke;
        padding-top: 100px;
        width: 100%;
        height: 100vh;

    }
    .home-carousel-div{
        height: 100%;
        width: 100%;
    }
    .carousel-img{
        width: 100%;
        height: auto;
        object-fit: fill;
    }
    .home-carousel{
        height: 100%;
    }
    .carousel-paper{
        height: auto;
    }
    .discount{
        display: block;
        /* height: 300px; */
        width: 30%;
    }
    .discount img{
        height: 250px;
        object-fit: contain;
    }
    .sticky-nav{
        display: block;
        pointer-events: none;
        height: 50px;
        width: 100%;
        background-color: none;
        padding: 0;
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }
    .sticky-nav.fixed {
        display: block;
        visibility: visible;
        background-color: whitesmoke;
        top: 0;
        left: 0;
        padding: 0;
        height: 50px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        opacity: 1;
        pointer-events: auto;
    }
    .sticky-nav-paper{
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: var(--text-black-color);
    }
    .sticky-nav-logo{
        color: var(--text-black-color);
    }
    .sticky-nav-logo a{
        text-decoration: none;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 450px;
        height: 100%;
    }
    .sticky-nav-navigation-in{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 600px;
        height: 100%;
    }
    .sticky-nav-navigation-opt-auth{
        display: flex;
        width:150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        border-left: 1px dotted var(--text-black-color);
        border-right: 1px dotted var(--text-black-color);
    }
    .sticky-nav-navigation-opt-auth a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-auth a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-auth a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-cart{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);
    }
    .sticky-nav-navigation-opt-cart a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-cart a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-cart a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-orders{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);
    }
    .sticky-nav-navigation-opt-orders a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-orders a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-orders a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-wishlist{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);;
    }
    .sticky-nav-navigation-opt-wishlist a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-wishlist a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-navigation-opt-wishlist a img{
        margin-right: 10px;
    }
    .sticky-nav-navigation-opt-logout{
        display: flex;
        width: 150px;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-right: 1px dotted var(--text-black-color);;
    }
    .sticky-nav-navigation-opt-logout a{
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        color: var(--text-black-color);
    }
    .sticky-nav-navigation-opt-logout a:hover{
        cursor: pointer;
        font-weight: bold;
    }
    .sticky-nav-search{
        width: 250px;
        height: 35px;
        border-radius: 15px;
        background-color: whitesmoke;
        border: 1pxgray solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sticky-talk-to-doc{
        display: flex;
        justify-content: flex-end;
        align-items:end;
        pointer-events: auto;
        height: 100px;
        width: 100%;
        background-color: none;
        padding: 0;
        z-index: 10000;
        position: fixed;
        top: 550px;
        left: 0;
        padding-bottom: 5px;
        padding-right: 10px;
    }
    .talk-to-doc-open{
        width: 150px;
        height: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 1px  black;
        color:white;
        font-weight: normal;
        cursor: pointer;
        padding: 5px;
        text-align: center;
    }
    .talk-to-doc-closed{
        display: none;
    }
    .close-talk-to-doc{
        height: auto;
        width: auto;
        padding: 0;
        padding-right: 2px;
        padding-bottom: 2px;
        position: fixed;
        color: #4158D0;
        font-size: small;
        cursor: pointer;
    }
    .close-talk-to-doc-off{
        display: none;
    }
    .talk-to-doc-toggle{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        border-radius: 5px;
    }
    .talk-to-doc-toggle-off{
        display: none;
    }
    .home-body{
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 30px;
        background-color: whitesmoke;
        min-height: 295vh;
        height: auto;
        transition: height 0.5s ease-in-out;
        overflow: hidden;
    }
    .carousel-no-images{
        height:20em;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .home-offer-one{
        padding: 40px;
        background-color: white;
    }
    .home-load-more{
        background-color: rgb(4, 2, 2);
        width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 2;
        transform: translateX(-50%);
    }
 } 