 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .manage-div{
        padding: 10px;
        background-color: whitesmoke;
    }
    .profile-paper{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .profile-image{
        border-radius: 50%;
        margin-top: 10px;
    }
    .profile-image img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
    .profile-data{
        width: 100%;
        padding: 10px;
    }
    .profile-data-entry{
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .profile-data-label-div{
        width: 25%;
    }
    .profile-data-d{
        width: 70%;
    }
    #profile-data-label{
        font-weight: bold;
        color: lightslategray;
    }
    #empty{
        font-size: small;
        color: gray;
        font-style: italic;
        margin-left: 10px;
    }
    .edit-profile{
        display: none;
    }
    .mob-edit-profile-paper{
        margin-top: 25%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mob-edit-profile-paper form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .mob-edit-profile-paper button{
        width: 150px;
        margin-top: 10px;
    }
    .mob-edit-profile-entry{
        width: 100%;
    }
    .profile-title{
     font-weight: bold;
     color: lightslategray;
     height: 50px;
     display: flex;
     justify-content: center;   
    }
    .edit-profile-button{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 5px;
    }
    .mob-edit-profile-modal{
        padding-left: 10px;
        padding-right: 10px;
    }
    .mob-edit-profile-name{
        background-color: coral;
    }
    .mob-edit-profile-entry{
        margin-bottom: 10px;
    }
    #mob-edit-label{
        font-size: small;
        color: gray;
        font-style: italic;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .manage-account-wrapper{
        min-height: 500px;
    }
    .manage-div{
        padding: 10px;
        background-color: whitesmoke;
    }
    .profile-paper{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .profile-image{
        border-radius: 50%;
        margin-top: 10px;
    }
    .profile-image img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
    .profile-data{
        width: 100%;
        padding: 10px;
    }
    .profile-data-entry{
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .profile-data-label-div{
        width: 25%;
    }
    .profile-data-d{
        width: 70%;
    }
    #profile-data-label{
        font-weight: bold;
        color: lightslategray;
    }
    #empty{
        font-size: small;
        color: gray;
        font-style: italic;
        margin-left: 10px;
    }
    .edit-profile{
        display: none;
    }
    .mob-edit-profile-paper{
        margin-top: 25%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mob-edit-profile-paper form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .mob-edit-profile-paper button{
        width: 150px;
        margin-top: 10px;
    }
    .mob-edit-profile-entry{
        width: 100%;
    }
    .profile-title{
     font-weight: bold;
     color: lightslategray;
     height: 50px;
     display: flex;
     justify-content: center;   
    }
    .edit-profile-button{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .mob-edit-profile-modal{
        padding-left: 10px;
        padding-right: 10px;
    }
    .mob-edit-profile-name{
        background-color: coral;
    }
    .mob-edit-profile-entry{
        margin-bottom: 10px;
    }
    #mob-edit-label{
        font-size: small;
        color: gray;
        font-style: italic;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .manage-account-wrapper{
        min-height: 500px;
    }
    .manage-div{
        background-color: whitesmoke;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 50px;
    }
    .profile-paper{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        /* width: 400px; */
    }
    .profile-image{
        border-radius: 50%;
        margin-top: 10px;
    }
    .profile-image img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
    .profile-data{
        width: 100%;
        padding: 10px;
    }
    .profile-data-entry{
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .profile-data-label-div{
        width: 15%;
    }
    .profile-data-d{
        width: 70%;
        font-size: 15px;
    }
    #profile-data-label{
        font-weight: bold;
        color: lightslategray;
    }
    #empty{
        font-size: small;
        color: gray;
        font-style: italic;
        margin-left: 10px;
    }
    .edit-profile{
        display: none;
    }
    .mob-edit-profile-paper{
        margin-top: 25%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mob-edit-profile-paper form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .mob-edit-profile-paper button{
        width: 150px;
        margin-top: 10px;
    }
    .mob-edit-profile-entry{
        width: 100%;
    }
    .profile-title{
     font-weight: bold;
     color: lightslategray;
     height: 50px;
     display: flex;
     justify-content: center;   
    }
    .edit-profile-button{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .mob-edit-profile-modal{
        padding-left: 10px;
        padding-right: 10px;
    }
    .mob-edit-profile-name{
        background-color: coral;
    }
    .mob-edit-profile-entry{
        margin-bottom: 10px;
    }
    #mob-edit-label{
        font-size: small;
        color: gray;
        font-style: italic;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .manage-account-wrapper{
        display: flex;
    }
    .manage-div{
        background-color: white;
        width: 70%;
        padding: 10px;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 50px;
    }
    .profile-paper{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 80%;
    }
    .mob-edit-profile-paper{
        /* margin-top: 25%; */
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mob-edit-profile-paper form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .mob-edit-profile-paper button{
        width: 150px;
        margin-top: 10px;
    }
    .mob-edit-profile-entry{
        width: 100%;
    }
    .profile-title{
     font-weight: bold;
     color: lightslategray;
     height: 50px;
     display: flex;
     justify-content: center;   
    }
    .edit-profile-button{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        background-color: whitesmoke;
    }
    /* .mob-edit-profile-modal{
        padding-left: 10px;
        padding-right: 10px;
    } */
    .mob-edit-profile-entry{
        margin-bottom: 10px;
    }
    #mob-edit-label{
        font-size: small;
        color: gray;
        font-style: italic;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .manage-div{

    }
    .mob-edit-profile-paper{
        margin-top: 5%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        height: 500px;
    }
    .mob-edit-profile-paper form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .mob-edit-profile-paper button{
        width: 150px;
        margin-top: 10px;
    }
    .mob-edit-profile-entry{
        width: 100%;
    }
    .profile-title{
     font-weight: bold;
     color: lightslategray;
     height: 50px;
     display: flex;
     justify-content: center;   
    }
    .edit-profile-button{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .mob-edit-profile-modal{
        display: flex;
        justify-content: center;
    }
    .mob-edit-profile-entry{
        margin-bottom: 10px;
    }
    #mob-edit-label{
        font-size: small;
        color: gray;
        font-style: italic;
    }
 }