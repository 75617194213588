 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .about-us-hero-image{
        width: 380px;
        height: 200px;
        background-color: coral;
    }
    .about-us-hero-image img{
        width: 100%;
        height: 300px;
        object-fit: contain;
    }
    .about-us-values-image img{
        object-fit: contain;
        height: inherit;
        width: 100%;
        height: 300px;
    }
    #values-div{
        margin-top: 20px;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .about-us-hero-image{
        width: 380px;
        height: 200px;

    }
    .about-us-hero-image img{
        width: 100%;
        height: 300px;
        object-fit: contain;
    }
    .about-us-values-image img{
        object-fit: contain;
        height: inherit;
        width: 100%;
        height: 300px;
    }
    #values-div{
        margin-top: 20px;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .about-us-hero-image{
        width: 760px;
        height: 250px;

    }
    .about-us-hero-image img{
        width: 100%;
        height: 300px;
        object-fit: contain;
    }
    .about-us-values-image img{
        object-fit: contain;
        height: inherit;
        width: 100%;
        height: 300px;
    }
    #values-div{
        margin-top: 20px;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .about-us-hero-image{
        width: 650px;
        height: 400px;
    }
    .about-us-hero-image img{
        width: 650px;
        height: 400px;
        object-fit: contain;
    }
    #our-story{
        margin-top: 80px;
    }
    .about-us-story{
        margin-bottom: 100px;
    }
    .about-us-values{
        background-color: coral;
    }
    .about-us-values-image{

    }
    .about-us-values-image img{
        object-fit: contain;
        height: inherit;
        width: 650px;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .about-us-hero-image{
        width: 650px;
        height: 400px;
    }
    .about-us-hero-image img{
        width: 650px;
        height: 400px;
        object-fit: contain;
    }
    #our-story{
        margin-top: 80px;
    }
    .about-us-story{
        margin-bottom: 100px;
    }
    .about-us-values{
        background-color: coral;
    }
    .about-us-values-image{

    }
    .about-us-values-image img{
        object-fit: contain;
        height: inherit;
        width: 650px;
    }
 }