
:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}
@keyframes pulse {
  0% {
      transform: scale(0);
      opacity: 0.5;
  }
  50% {
      transform: scale(1);
      opacity: 0;
  }
  100% {
      transform: scale(1.5);
      opacity: 0;
  }
}
  
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .search-div{
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
        width: 100%;
        margin: auto;
        position: relative;
        z-index: 2;
    }
    .search-input-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .search-input{
        height: 35px;
        width: 100%;
    }
    #search-button{
        margin-top: 10px;
        margin-left: 10px;
    }

    /* CUSTOM CAROUSEL */
    .custom-carousel-div{
        height: auto;
        padding-bottom: 20px;
        width: 100%;
        background-color:white;
        margin-bottom: 10px;
        margin-top: 10px;
        /* border-top: greenyellow 1.5px dotted;
        border-bottom: greenyellow 1.5px dotted; */
    }
    .custom-carousel-wrapper{
        width: 100%;
        height: 100%;
    }
    .custom-carousel-title{
        display: flex;
        align-items: baseline;
        color:white;
        height: auto;
        border-top: 1px dotted;
        border-bottom: 1px dotted;
        padding: 10px 10px 15px 10px;
        justify-content: space-between;
        font-weight: bold;
    }
    .custom-carousel-title h4{
        font-weight: bold;
        color: var(--text-black-color);
        display: block;
        width: 50%;
    }
    .custom-carousel-title p{
        display: block;
        font-weight: bold;
        color: black;
    }
    #custom-carousel-btn{
        color: black;
    }
    .custom-carousel-no-data{
      height: 25em;
      width: 14em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    /* PRODUCT CARD */
    .custom-card{
        min-height: 320px;
        width: 180px;
        margin: 10px;
        padding-bottom: 10px;
        background-color: coral;
    }
    .custom-card a{
        text-decoration: none;
        color: #36454F;
    }
    .product-card-img{
      display: flex;
      justify-content: center;
    }
    .product-card-img img{
        width: inherit;
        height: 120px;
        width: 100%;
        object-fit: cover;
    }
    .product-name{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 5px;
        font-size: 17px;
        padding-top: 15px;
        height: 60px;
        font-weight: bold;
        color: black;
    }
    .product-rating{
      padding: 5px 10px 0px 5px;
    }
    .product-current-price{
        padding: 5px;
    }

    #product-current-price-orig{
        font-size: 15px;
        font-weight: bold;
        height: 40px;
        display: flex;
        align-items: center;
        color: var(--text-black-color);
    }
    #product-old-price{
        text-decoration: line-through;
        padding: 0;
        margin-top: -12px;
        color: var(--text-black-color);
        font-size: 12px;
    }
    .product-add-to-cart{
      display: flex;
      justify-content: space-evenly;
    }
    .product-view-more{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }

    /* PRODUCT RATING */
    .rating{
      width: 100px;
      height: 30px;
      padding: 2px;
      border-radius: 2px;
      border:lime 1px dotted;
      background-color: var(--light-red-color);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px;
      font-weight: bold;
    }


    /* BANNER */
    .banner-div{
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 0px 5px 0px 5px;
        cursor: pointer;
    }
    .banner-div img{
        height: 100px;
        width: 100%;
        object-fit: cover;
    }
    .ctamob{
      display: block;
    }
    .ctadsk{
      display: none;
    }
    .ctaipd{
      display: none;
    }
    /* BREADCRUMBS */
    .breadcrumb{
      display: flex;
      height: auto;
      padding-left: 10px;
      padding-bottom: 5px;
      background-color: whitesmoke;
    }
    .breadcrumb a{
      text-decoration: none;
    }
    .home-crumb{
      display: flex;
      height: 100%;
      width: 60px;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      color: gray;
      font-size: 12px;
    }
    .home-crumb p{
      margin-top: 5px;
    }
    .nav-crumb-slash{
      height: 100%;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: gray;
      padding-top: 3px;
    }
    .nav-crumb{
      height: 100%;
      width: auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-weight: bold;
      color: gray;
      padding-top: 5px;
      font-size: 12px;
    }
    .nav-crumb:hover{
      text-decoration: underline;
    }

      /* SIMILAR PRODUCT CARD */
      .similar-product-card{
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 150px;
        width: 45%;
        margin-top: 5px;
      }
      .similar-product-card a{
        text-decoration: none;
        color: lightslategray;
      }
      .similar-product-image-div{
        padding: 5px;
      }
      .similar-product-image-div img{
        width: 100%;
        height: 100px;
      }
      .similar-product-name{
        text-align: center;
        padding: 5;
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }

      /* CART PRODUCT CARD */
      .cart-product-card{
        margin-bottom: 15px;
      }
      .cart-product-name{
        padding: 10px;
        font-weight: bold;
        background-color: whitesmoke;
      }
      .cart-product-data{
        padding: 0px 10px 10px 10px;
        display: flex;
      }
      .cart-product-label{
        width: 40%;
      }
      .cart-product-buttons{
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 10px 20px;
      }
      .cart-product-buttons button{
        width: 100px;
      }
      .cart-product-vars{
        width: auto;
        height: 100%;
      }

    /* SEARCH PRODUCT CARD */
    .search-product-link{
        text-decoration: none;
        padding: 0px;
    }
    .search-product-paper{
        display: flex;
        flex-direction: row;
        height: 170px;
        width: 90%;
        margin-bottom: 50px;
        position: relative;
    }
    .search-product-img{
        width:200px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .search-product-img img{
        object-fit: contain;
        height: inherit;
    }
    .search-product-desc{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .search-product-data{
      width: 100%; 
    }
    .search-product-icons{
        padding-top: 10px;

    }
    .search-product-title{
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient: vertical;
      height: 50px;
    }
    .search-product-category{
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient: vertical;
      height: 40px;
    }
 } 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .search-div{
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
        width: 100%;
        margin: auto;
        position: relative;
        z-index: 2;
    }
    .search-input-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .search-input{
        height: 35px;
        width: 100%;
    }
    #search-button{
        margin-top: 10px;
        margin-left: 10px;
    }

    /* BREADCRUMBS */
    .breadcrumb{
      display: flex;
      height: 35px;
      padding-left: 10px;
      background-color: whitesmoke;
    }
    .breadcrumb a{
      text-decoration: none;
    }
    .home-crumb{
      display: flex;
      height: 100%;
      width: 60px;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      color: gray;
      font-size: 12px;
    }
    .home-crumb p{
      margin-top: 5px;
    }
    .nav-crumb-slash{
      height: 100%;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: gray;
      padding-top: 3px;
    }
    .nav-crumb{
      height: 100%;
      width: auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-weight: bold;
      color: gray;
      padding-top: 5px;
      font-size: 12px;
    }
    .nav-crumb:hover{
      text-decoration: underline;
    }

    /* CUSTOM CAROUSEL */
    .custom-carousel-div{
      height: auto;
      padding-bottom: 20px;
      width: 100%;
      background-color:white;
      margin-bottom: 10px;
      margin-top: 10px;
      /* border-top: greenyellow 1.5px dotted;
      border-bottom: greenyellow 1.5px dotted; */
    }
    .custom-carousel-wrapper{
        background-color: white;
        width: 100%;
        height: 100%;
    }
    .custom-carousel-title{
      display: flex;
      align-items: baseline;
      color:white;
      height: auto;
      border-top: 1px dotted;
      border-bottom: 1px dotted;
      padding: 10px 10px 15px 10px;
      justify-content: space-between;
      font-weight: bold;
  }
  .custom-carousel-title h4{
      font-weight: bold;
      color: var(--text-black-color);
      display: block;
      width: 50%;
  }
    .custom-carousel-title p{
        display: block;
        font-weight: bold;
        color: black;
    }
    #custom-carousel-title-btn{
        color: white;
    }
    .custom-carousel{
      padding-top: 10px;
    }
    .custom-carousel-no-data{
      height: 25em;
      width: 30em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    /* PRODUCT CARD */
    .custom-card{
        min-height: 320px;
        margin: 10px;
        padding-bottom: 10px;
    }
    .custom-card a{
        text-decoration: none;
        color: #36454F;
    }
    .product-card-img img{
        width: 100%;
        height: 120px;
        object-fit: cover;
    }
    .product-name{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* height: 100px; */
        overflow: hidden;
        padding: 5px;
        font-size: 17px;
        padding-top: 15px;
        height: 65px;
        font-weight: bold;
        color: black;
    }
    .product-rating{
      padding: 5px 10px 0px 5px;
    }
    .product-current-price{
        padding: 5px;
    }

    #product-current-price-orig{
        font-size: 15px;
        font-weight: bold;
        color: var(--text-black-color);
    }
    #product-old-price{
        text-decoration: line-through;
        color: var(--text-black-color);
        padding: 0;
        /* margin-top: -12px; */
        /* font-size: 12px; */
    }
    .product-add-to-cart{
      display: flex;
      justify-content: space-evenly;
    }
    .product-view-more{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
    
    /* PRODUCT RATING */

    .rating{
      width: 100px;
      height: 30px;
      padding: 2px;
      border-radius: 2px;
      border:lime 1px dotted;
      background-color: var(--light-red-color);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px;
      font-weight: bold;
    }

    /* BANNER */
    .banner-div{
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        cursor: pointer;
    }
    .banner-div img{
        height: 100px;
        /* width: 100%; */
        object-fit: contain;
    }
    .ctamob{
      display: none;
    }
    .ctadsk{
      display: none;
    }
    .ctaipd{
      display: block;
    }
      /* SIMILAR PRODUCT CARD */
      .similar-product-card{
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 150px;
        width: 25%;
        margin-top: 5px;
      }
      .similar-product-card a{
        text-decoration: none;
        color: lightslategray;
      }
      .similar-product-image-div{
        padding: 5px;
      }
      .similar-product-image-div img{
        width: 100%;
        height: 100px;
      }
      .similar-product-name{
        text-align: center;
        padding: 5;
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }

    /* SEARCH PRODUCT CARD */
    .search-product-link{
      text-decoration: none;
      padding: 0px;
    }
    .search-product-paper{
        display: flex;
        flex-direction: row;
        height: 170px;
        width: 95%;
        margin-bottom: 50px;
        position: relative;
    }
    .search-product-img{
        width:200px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .search-product-img img{
          object-fit: contain;
          height: inherit;
      }
      .search-product-desc{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
      }
      .search-product-data{
        width: 100%; 
      }
      .search-product-icons{
          padding-top: 10px;

      }
      .search-product-title{
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;
        height: 40px;
      }
      .search-product-category{
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;
        height: 40px;
      }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    /* BANNER */
    .ctamob{
      display: none;
    }
    .ctadsk{
      display: block;
    }
    .ctaipd{
      display: none;
    }
    /* PRODUCT CARD */
    .custom-card{
        min-height: 320px;
        margin: 10px;
        padding-bottom: 10px;
        width: 200px;
    }
    .custom-card a{
        text-decoration: none;
        color: #36454F;
    }
    .product-card-img{
      min-height:200px
    }
    .product-card-img img{
        width: inherit;
        height: 200px;
        object-fit: cover;
    }
  
    .product-name{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 5px;
        font-size: 17px;
        padding-top: 15px;
        height: 65px;
        font-weight:300;
        color:black;
        font-weight: bold;
    }
    .product-current-price{
        padding: 5px;
    }

    .product-rating{
      padding: 5px 10px 0px 5px;
    }
    .product-view-more{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
    /* PRODUCT RATING */
    .rating{
      width: 50px;
      height: 25px;
      background-color: var(--light-red-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      padding: 4px;
      border-radius: 3px;
      font-weight: bold;
    }

    /* CUSTOM CAROUSEL */
    .custom-carousel-div{
      height: auto;
      width: 100%;
      margin-top: 30px;
  }
    .custom-carousel-wrapper{
        width: 100%;
        height: 100%;
    }
    .custom-carousel-title{
      height: auto;
        display: flex;
        align-items: baseline;
        color:#36454F;
        padding: 10px 50px 30px 30px;
        justify-content: space-between;
        border-style: none;
    }
    .custom-carousel-title h4{
      display: block;
      font-weight: bold;
      color: var(--text-black-color);
      font-family: 'Noto Sans Mongolian', sans-serif;
      font-family: 'Roboto', sans-serif;
    }
    .custom-carousel-title p{
        display: block;
        font-weight: bold;
        color: var(--text-black-color);
    }
    #custom-carousel-title-btn{
      color: var(--red-color);
    }

    .custom-carousel{
        padding-top: 10px;
        padding-left: 10px;
    }
    .custom-carousel-no-data{
      height: 15em;
      width: 48em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
      /* SIMILAR PRODUCT CARD */
      .similar-product-card{
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 250px;
        width: 100%;
        margin-top: 5px;
      }
      .similar-product-card a{
        text-decoration: none;
        color: lightslategray;
      }
      .similar-product-image-div{
        padding: 5px;
      }
      .similar-product-image-div img{
        width: 100%;
        height: 100px;
      }
      .similar-product-name{
        text-align: center;
        padding: 5;
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }
      /* SEARCH PRODUCT CARD */

      .search-product-link{
        text-decoration: none;
      }
      .search-product-paper{
        display: flex;
        flex-direction: row;
        height: auto;
        width: 600px;
        margin-bottom: 50px;
        color:var(--text-black-color);
      }
      .search-product-img{
        width: auto;
        height: 150px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .search-product-img img{
        object-fit: contain;
        height: inherit;
      }
      .search-product-desc{
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: row;
        padding: 10px;
        /* background-color: crimson; */
      }
      .search-product-data{
        width: auto;
        color: var(--text-black-color);
      }
      .search-product-icons{
        padding-left: 100px;
      }
      .search-product-tap{
        /* background-color: coral; */
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) { 
    /* html search */
    .search{
        height: 300px;
        width: 100px;
        position: absolute;
        z-index: 1;
    }

    /* SEARCH BAR */
    .search-div{
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
        width: 700px;
        max-width: 700px;
        margin: auto;
        position: relative;
        z-index: 2;
    }
    .search-input{
        height: 35px;
        width: 500px;
    }
    .search-input-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 600px;
    }
    #search-button{
        margin-top: 10px;
    }
    .results-div{
        position: relative;
        width: 500px;
        margin-top: 1px;
        max-height: 200px;
        z-index: 3;
        
    }
    .results-paper{
        top: 100%;
        width: 100%;
        padding: 5px;
        max-height: 200px;
        overflow: hidden;
        overflow-y: auto;
    }
    .results-tag{
        padding: 2px 2px 2px 2px;
    }
    .results-tag:hover{
        background-color: whitesmoke;
        cursor: pointer;
    }

    /* CUSTOM CAROUSEL */
    .custom-carousel-div{
        height: auto;
        width: 100%;
        margin-top: 30px;
    }
    .custom-carousel-wrapper{
        width: 100%;
        height: 100%;
    }
    .custom-carousel-title{
      height: auto;
        display: flex;
        align-items: baseline;
        color:#36454F;
        padding: 10px 50px 30px 30px;
        justify-content: space-between;
        border-style: none;
    }
    .custom-carousel-title h4{
      display: block;
      font-weight: bold;
      color: var(--text-black-color);
      font-family: 'Noto Sans Mongolian', sans-serif;
      font-family: 'Roboto', sans-serif;
    }
    .custom-carousel-title p{
        display: none;
    }
    .custom-carousel-title a{
        margin-left: 30px;
        font-size: 15px;
        text-decoration: none;
    }
    #custom-carousel-title-btn{
        color: var(--red-color);
    }
    .custom-carousel{
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .custom-carousel-no-data{
      height: 15em;
      width: 48em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    /* PRODUCT CARD */
    .custom-card{
        min-height: 320px;
        width: 250px;
        margin: 10px;
        padding-bottom: 10px;
    }
    .custom-card a{
        text-decoration: none;
        color: var(--text-black-color);
    }
    .product-discount-percentage{
      position: absolute;
      background-color: var(--light-red-color);
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      top: 10px;
      left: 13px;
      padding: 5px;
      height: 65px;
    }
    .product-card-img{
        min-height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-card-img img{
        width: inherit;
        height: 200px;
        object-fit: cover;
    }
    .product-name{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 5px;
        font-size: 17px;
        padding-top: 15px;
        height: 65px;
        font-weight:300;
        color:black;
        font-weight: bold;
    }
    .product-rating{
      padding: 5px 10px 0px 5px;
    }
    .product-current-price{
        padding: 5px;
    }
    .product-price-diff{
      /* background-color: coral; */
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .product-o-prc{
      /* background-color: aqua; */
      padding-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #product-current-price-orig{
        /* font-size: 17px; */
        /* font-weight: bold; */
        color: var(--text-black-color);
    }
    #product-old-price{
        text-decoration: line-through;
        padding: 0;
        /* margin-top: -12px; */
        color: var(--text-black-color);
        font-size: 12px;
    }
    .product-view-more{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }

    /* PRODUCT RATING */
    .rating{
      width: 100px;
      height: 30px;
      padding: 2px;
      border-radius: 2px;
      border:lime 1px dotted;
      background-color: var(--light-red-color);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px;
      font-weight: bold;
    }
    /* BANNER */
    .banner-div{
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        padding: 0px 5px 0px 5px;
        cursor: pointer;
    }
    .banner-div img{
        height: 100px;
        width: 100%;
        /* object-fit: cover; */
    }
    .ctamob{
      display: none;
    }
    .ctadsk{
      display: block;
    }
    .ctaipd{
      display: none;
    }

    /* BREADCRUMBS */
      .breadcrumb{
        display: flex;
        height: 40px;
        padding-left: 10px;
        /* background-color: whitesmoke; */
      }
      .breadcrumb a{
        text-decoration: none;
      }
      .home-crumb{
        display: flex;
        height: 100%;
        width: 60px;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        color: gray;
        font-size: 12px;
      }
      .home-crumb p{
        margin-top: 5px;
      }
      .nav-crumb-slash{
        height: 100%;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: gray;
        padding-top: 2px;
      }
      .nav-crumb{
        height: 100%;
        width: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-weight: bold;
        color: gray;
        padding-top: 5px;
        font-size: 12px;
      }
      .nav-crumb:hover{
        text-decoration: underline;
      }

      /* SEARCH PRODUCT CARD */

      .search-product-link{
        text-decoration: none;
      }
      .search-product-paper{
        display: flex;
        flex-direction: row;
        height: auto;
        margin-bottom: 50px;
        color:var(--text-black-color);
      }
      .search-product-img{
        width: auto;
        height: 150px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .search-product-img img{
        object-fit: contain;
        height: inherit;
      }
      .search-product-desc{
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: row;
        padding: 10px;
        /* background-color: crimson; */
      }
      .search-product-data{
        width: auto;
        color: var(--text-black-color);
      }
      .search-product-icons{
        padding-left: 100px;
      }
      .search-product-title{
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;
        height: auto;
      }
      .search-product-tap{
        /* background-color: coral; */
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      /* DROPDOWN MENU */
      .dropdown-menu ul {
        list-style: none;
        padding: unset;
        margin: unset;
      }
      
      .dropdown-menu ul li {
        padding: 14px;
        transition: all linear 0.3s;
      }
      
      .dropdown-menu ul li:hover {
        background: gray;
        cursor: pointer;
        color: #fff;
      }
      
      .dropdown-menu {
        background: #fff;
        box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
        transition: all linear 0.3s;
        position: relative;
        z-index: 2;
      }
 }