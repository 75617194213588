:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}
 
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .search-box{
        height: 30px;
        width: 250px;
        border-radius: 5px;
        border:1px solid;
        border-color: gray;
    
    }
    .search-box-div{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-box-div .ais-SearchBox-input{
        border: 1px solid gray;
        border-radius: 25px;
        padding-left: 10px;
        width: 300px;
        outline: none;
    }
    .search-box-div .ais-SearchBox-submit{
        display: none;
    }
    .search-box-div .ais-SearchBox-reset{
        margin-left: 10px;
        border: none;
        background-color: white;
        color: var(--red-color);
    }
    .search-results{
        display: flex;
        flex-direction: column  ;
    }
    .search-results-label{
        background-color: whitesmoke;
        padding-left: 10px;
        height: 30px;
        display: flex;
        align-items: center;
    }
    .results{
        padding-top: 10px;
        min-height: 120vh;
    }
    .search-results-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
    }
    .search-results-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-results-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-results-pagination .ais-Pagination-item{
        width: 25px;
    }
    .search-recommendations{

    }
    .search-offers{

    }
    .search-side-bar-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
    }
    .search-side-offers{
        height:auto;
        margin: 0;
        padding: 10px;
    }
    .search-offer-paper{
        height: auto;
        width: 100%;
        border-radius: 25px;  
    }
    .search-offer-paper img{
        object-fit: contain;
        width: inherit;
        height: inherit;
    }
    .search-side-bar-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
        background-color: whitesmoke;        
    }
    .search-side-bar-title-featured{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
        background-color: whitesmoke;
        margin-top: 30px;
        width: 98%;
    }
    .search-featured-products{
        height: 400px;
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 30px;
        margin-top: 10px;
        position: relative;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .search-box{
        height: 30px;
        width: 250px;
        border-radius: 5px;
        border:1px solid;
        border-color: gray;
    
    }
    .search-box-div{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-box-div .ais-SearchBox-input{
        border: 1px solid gray;
        border-radius: 25px;
        padding-left: 10px;
        outline: none;
    }
    .search-box-div .ais-SearchBox-submit{
        display: none;
    }
    .search-box-div .ais-SearchBox-reset{
        margin-left: 10px;
        border: none;
        background-color: white;
        color: var(--red-color);
    }

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .search-box{
        height: 30px;
        width: 350px;
        border-radius: 5px;
        border:1px solid;
        border-color: gray;
    
    }
    .search-box-div{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-box-div .ais-SearchBox-input{
        border: 1px solid gray;
        border-radius: 25px;
        padding-left: 10px;
        outline: none;
        width: 400px;
    }
    .search-box-div .ais-SearchBox-submit{
        display: none;
    }
    .search-box-div .ais-SearchBox-reset{
        margin-left: 10px;
        border: none;
        background-color: white;
        color: var(--red-color);
    }
    .results{
        padding-top: 10px;
        min-height: 120vh;
    }
    .search-results-label{
        background-color: whitesmoke;
        padding-left: 10px;
        height: 30px;
        display: flex;
        align-items: center;
    }
    .search-results-label{
        background-color: whitesmoke;
        padding-left: 10px;
        height: 30px;
        display: flex;
        align-items: center;
    }
    .search-results-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
    }
    .search-results-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-results-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-results-pagination .ais-Pagination-item{
        width: 25px;
    }
    .search-side-bar-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
    }
    .search-side-offers{
        height:auto;
        margin: 0;
        padding: 10px;
    }
    .search-offer-paper{
        height: auto;
        width: 100%;
        border-radius: 25px;  
    }
    .search-offer-paper img{
        object-fit: contain;
        width: inherit;
        height: inherit;
    }
    .search-featured-products{
        height: 700px;
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 30px;
        margin-top: 10px;
        position: relative;
    }
    .search-side-bar-title-featured{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
        background-color: whitesmoke;
        margin-top: 30px;
        width: 98%;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .search-box{
        height: auto;
        width: 500px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border:1px solid;
        border-color: lightslategray;
    
    }
    .search-box-div{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-results{
        display: flex;
        flex-direction: row;
    }
    .search-results-div{
        width: 70%;
        min-height: 100vh;
    }
    .search-results-label{
        background-color: whitesmoke;
        padding-left: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .search-spacer{
        background: white;
        height: 100%;
        width: 10px;
    }
    .results{
        padding-top: 10px;
        min-height: 120vh;
    }
    .search-results-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
    }
    .search-results-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-results-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-results-pagination .ais-Pagination-item{
        width: 25px;
    }
    .search-recommendations{
        width: 35%;
        height: 300vh;
    }
    .search-side-offers{
        height:auto;
        margin: 0;
        padding: 10px;
    }
    .search-offer-paper{
        height: auto;
        width: 100%;
        border-radius: 25px;  
    }
    .search-offer-paper img{
        object-fit: contain;
        width: inherit;
        height: inherit;
    }
    .search-featured-products{
        height: 700px;
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 30px;
        margin-top: 10px;
        position: relative;
    }
    .search-side-bar-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
        background-color: whitesmoke;        
    }
    .search-side-bar-title-featured{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
        background-color: whitesmoke;
        margin-top: 30px;
        width: 98%;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .search-box{
        height: auto;
        width: 500px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border:1px solid;
        border-color: lightslategray;
    
    }
    .search-box-div{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-results{
        display: flex;
        flex-direction: row;
    }
    .search-results-div{
        width: 70%;
        min-height: 100vh;
    }
    .results{
        padding-top: 10px;
        min-height: 120vh;
    }
    .search-results-label{
        background-color: whitesmoke;
        padding-left: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .search-spacer{
        background: white;
        height: 100%;
        width: 10px;
    }
    .search-results-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
    }
    .search-results-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-results-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-results-pagination .ais-Pagination-item{
        width: 25px;
    }
    .search-recommendations{
        width: 35%;
        height: 300vh;
    }
    .search-side-offers{
        height:auto;
        margin: 0;
        padding: 10px;
    }
    .search-offer-paper{
        height: auto;
        width: 100%;
        border-radius: 25px;  
    }
    .search-offer-paper img{
        object-fit: contain;
        width: inherit;
        height: inherit;
    }
    .search-featured-products{
        height: 700px;
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 30px;
        margin-top: 10px;
        position: relative;
    }
    .search-side-bar-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
        background-color: whitesmoke;        
    }
    .search-side-bar-title-featured{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
        background-color: whitesmoke;
        margin-top: 30px;
        width: 98%;
    }
    .search-skeleton{
        margin-left: 10px;
        margin-top: 10px;
    }
 } 