 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .medicine-page{
        background-color: whitesmoke;
    }
    .search-form{
        width: 100%;
        height: auto;
        background-color: white;
    }
    .medicine-body{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        justify-content: space-between;
        padding: 0px 10px 0px 10px;
    }
    .filter-div{
        display: none;
    }
    .medicine{
        width: 100%;
        min-height: 100vh;
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        background-color: white;
        padding-top: 10px;
        border-radius: 10px;
    }
    .category-div{
        /* background-color:red ;    */
    }
    .filter-heading{
        margin-bottom: 10px;
    }
    .mob-filter-div{
        display: block;
    }
    .medicine-pagination-div{
        margin-top: auto;
    }
    #basic-button{
        background-color: gray;
        color: white;
        margin-bottom: 25px;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {}
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .medicine-page{
        background-color: whitesmoke;
    }
    .search-form{
        width: 100%;
        height: auto;
        background-color: white;
    }
    .medicine-body{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        justify-content: space-between;
        padding: 0px 20px 0px 20px;
    }
    .filter-div{
        display: none;
    }
    .mob-filter-div{
        display: block;
    }
    .medicine{
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }
    .category-div{
        /* background-color:red ;    */
    }
    .filter-heading{
        margin-bottom: 10px;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .medicine-page{
        background-color: whitesmoke;
    }
    .search-form{
        width: 100%;
        height: auto;
        background-color: white;
    }
    .medicine-body{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-between;
        padding: 0px 20px 0px 20px;
    }
    .filter-div{
        width: 15%;
        background-color: white;
        /* height: 100vh; */
        padding: 10px;
        height: 70vh;
        border-radius: 10px;
    }
    .mob-filter-div{
        display: none;
    }
    .medicine{
        width: 83%;
        display: flex;
        overflow: hidden;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }
    .category-div{
        /* background-color:red ;    */
    }
    .filter-heading{
        margin-bottom: 10px;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .medicine-page{
        background-color: whitesmoke;
    }
    .search-form{
        width: 100%;
        height: auto;
        background-color: white;
    }
    .medicine-body{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-between;
        padding: 0px 20px 0px 20px;
    }
    .filter-div{
        display: block;
        width: 15%;
        background-color: white;
        /* height: 100vh; */
        padding: 10px;
        height: auto;
        border-radius: 10px;
    }
    .mob-filter-div{
        display: none;
    }
    .medicine{
        width: 83%;
        min-height:100vh;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }
    .category-div{
        /* background-color:red ;    */
    }
    .filter-heading{
        margin-bottom: 10px;
    }
    .filter-tag{
        padding-bottom: 10px;
    }
    .sex-filter{

    }
    .brand-filter{
        margin-top:20px;
    }
    .category-filter{
        margin-top: 20px;
    }
 }