/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .search-pdt-card{
        display: flex;
        flex-direction: column;
        height: 300px;
    }
    .search-pdt-img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
    }
    .search-pdt-img img{
        width: 150px;
    }
    .search-pdt-detail{
        padding-top: 5px;
        padding-left: 10px;
    }
    #search-pdt-title{
        font-size: 17px;
        font-weight: bold;
    }
    #search-pdt-manufacturer{
        font-size: 12px;
        margin-top: -15px;
    }
    .search-pdt-dsctd{
        width: auto;
        max-width: 200px;
        display: flex;
    }
    #search-pdt-orig-price{
        text-decoration: line-through;
        margin-right: 10px;
    }
    #search-pdt-new-price{
        font-size: 17px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .search-pdt-card{
        display: flex;
        flex-direction: column;
        height: 300px;
    }
    .search-pdt-img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
    }
    .search-pdt-img img{
        width: 150px;
    }
    .search-pdt-detail{
        padding-top: 5px;
        padding-left: 10px;
    }
    #search-pdt-title{
        font-size: 17px;
        font-weight: bold;
    }
    #search-pdt-manufacturer{
        font-size: 12px;
        margin-top: -15px;
    }
    .search-pdt-dsctd{
        width: auto;
        max-width: 200px;
        display: flex;
    }
    #search-pdt-orig-price{
        text-decoration: line-through;
        margin-right: 10px;
    }
    #search-pdt-new-price{
        font-size: 17px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .search-pdt-card{
        display: flex;
        flex-direction: row;
        width:600px;
        height:120px;
    }
    .search-pdt-img{
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-pdt-img img{
        width: 120px;
    }
    .search-pdt-detail{
        padding-top: 10px;
    }
    #search-pdt-title{
        font-size: 17px;
        font-weight: bold;
    }
    #search-pdt-manufacturer{
        font-size: 12px;
        margin-top: -10px;
    }
    .search-pdt-dsctd{
        width: auto;
        max-width: 200px;
        display: flex;
    }
    #search-pdt-orig-price{
        text-decoration: line-through;
        margin-right: 10px;
    }
    #search-pdt-new-price{
        font-size: 17px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .search-pdt-card{
        display: flex;
        width:600px;
        height:120px;
    }
    .search-pdt-img{
        width: 30%;
        height: 100%;
    }
    .search-pdt-img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-pdt-detail{
        padding-top: 10px;
    }
    #search-pdt-title{
        font-size: 17px;
        font-weight: bold;
    }
    #search-pdt-manufacturer{
        font-size: 12px;
        margin-top: -10px;
    }
    .search-pdt-dsctd{
        /* background-color: coral; */
        width: auto;
        max-width: 200px;
        display: flex;
    }
    #search-pdt-orig-price{
        text-decoration: line-through;
        margin-right: 10px;
    }
    #search-pdt-new-price{
        font-size: 17px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .search-pdt-card{
        display: flex;
        width:600px;
        height:120px;
    }
    .search-pdt-img{
        width: 30%;
        height: 100%;
    }
    .search-pdt-img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-pdt-detail{
        padding-top: 10px;
    }
    #search-pdt-title{
        font-size: 17px;
        font-weight: bold;
    }
    #search-pdt-manufacturer{
        font-size: 12px;
        margin-top: -10px;
    }
    .search-pdt-dsctd{
        /* background-color: coral; */
        width: auto;
        max-width: 200px;
        display: flex;
    }
    #search-pdt-orig-price{
        text-decoration: line-through;
        margin-right: 10px;
    }
    #search-pdt-new-price{
        font-size: 17px;
    }
}