:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .drugs-express-logo{
        width: 150px;
        height: 30px;
    }
    .customer-add-paper{
        width: 400px;
        height: 120px;
        padding: 5px;
        margin-top: 30px;
        margin-left: 5px;
    }
    .customer-add-title{
        margin-left: 20px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .customer-add-contact{
        font-size: 12px;
        color: var(--text-black-color);
    }
    .customer-add-name{
        font-size: 17px;
        color: var(--text-black-color);
    }
    .customer-delivery-loc{
        margin-top: 45px;
        margin-left: 5px;
        padding: 5px;
        color: var(--text-black-color);
        width: 400px;
    }
    .delivery-loc-search{
        box-sizing: border-box;
        border: 1px solid;
        width: 390px;
        height: 32px;
        padding: 0 12px;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        margin-top: 10px;
        margin-bottom:50px;
    }
    .map-wrapper{
        height: 400px;
        width: 400px;
    }
    .google-map-box{
        width: 387px;
        height: 400px;
    }
    .customer-payment{
        margin-top: 45px;
        margin-left: 5px;
        width: 400px;
        padding: 5px;
        color: var(--text-black-color);
    }
    .pay-on-delivery-card{
        padding: 5px;
        width: 200px;
        height: 100px;
    }
    .momo-pay-card{
        padding: 5px;
        width: 200px;
        height: 100px;

    }
    .customer-pay-on-dlvr span{
        margin-left: 10px;
    }
    .customer-chk-card{
        padding: 5px;
        margin-top: 45px;
        margin-left: 5px;
        width: 400px;
    }
    .checkout-wrapper{
        margin-bottom: 50px;
    }
    .customer-chk-sum-title{
        font-size: 18px;
        color: var(--text-black-color);
    }
    .chk-title{
        color: var(--text-black-color);
    }
    .chk-btn{
        width: 300px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .drugs-express-logo{
        width: 150px;
        height: 30px;
    }
    .customer-add-paper{
        width: 400px;
        height: 120px;
        padding: 5px;
        margin-top: 30px;
        margin-left: 5px;
    }
    .customer-add-title{
        margin-left: 20px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .customer-add-contact{
        font-size: 12px;
        color: var(--text-black-color);
    }
    .customer-add-name{
        font-size: 17px;
        color: var(--text-black-color);
    }
    .customer-delivery-loc{
        margin-top: 45px;
        margin-left: 5px;
        padding: 5px;
        color: var(--text-black-color);
        width: 400px;
    }
    .delivery-loc-search{
        box-sizing: border-box;
        border: 1px solid;
        width: 390px;
        height: 32px;
        padding: 0 12px;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        margin-top: 10px;
        margin-bottom:50px;
    }
    .map-wrapper{
        height: 400px;
        width: 400px;
    }
    .google-map-box{
        width: 387px;
        height: 400px;
    }
    .customer-payment{
        margin-top: 45px;
        margin-left: 5px;
        width: 400px;
        padding: 5px;
        color: var(--text-black-color);
    }
    .pay-on-delivery-card{
        padding: 5px;
        width: 200px;
        height: 100px;
    }
    .momo-pay-card{
        padding: 5px;
        width: 200px;
        height: 100px;

    }
    .customer-pay-on-dlvr span{
        margin-left: 10px;
    }
    .customer-chk-card{
        padding: 5px;
        margin-top: 45px;
        margin-left: 5px;
        width: 400px;
    }
    .checkout-wrapper{
        margin-bottom: 50px;
    }
    .customer-chk-sum-title{
        font-size: 18px;
        color: var(--text-black-color);
    }
    .chk-title{
        color: var(--text-black-color);
    }
    .chk-btn{
        width: 300px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .drugs-express-logo{
        width: 250px;
        height: 60px;
    }
    .customer-add-paper{
        width: 750px;
        height: 120px;
        padding: 5px;
        margin-top: 30px;
        margin-left: 10px;
    }
    .customer-add-title{
        margin-left: 20px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .customer-add-contact{
        font-size: 12px;
        color: var(--text-black-color);
    }
    .customer-add-name{
        font-size: 17px;
        color: var(--text-black-color);
    }
    .customer-delivery-loc{
        margin-top: 45px;
        margin-left: 10px;
        padding: 5px;
        color: var(--text-black-color);
        width: 750px;
    }
    .delivery-loc-search{
        box-sizing: border-box;
        border: 1px solid;
        width: 735px;
        height: 32px;
        padding: 0 12px;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        margin-top: 10px;
        margin-bottom:50px;
    }
    .map-wrapper{
        height: 400px;
        width: 735px;
    }
    .google-map-box{
        width: 735px;
        height: 400px;
    }
    .customer-payment{
        margin-top: 45px;
        margin-left: 10px;
        width: 750px;
        padding: 5px;
        color: var(--text-black-color);
    }
    .pay-on-delivery-card{
        padding: 5px;
        width: 200px;
        height: 100px;
    }
    .momo-pay-card{
        padding: 5px;
        width: 200px;
        height: 100px;

    }
    .customer-pay-on-dlvr span{
        margin-left: 10px;
    }
    .customer-chk-card{
        margin-left: 10px;
        padding: 5px;
        width: 750px;
        margin-top: 30px;
    }

    .customer-chk-sum-title{
        font-size: 18px;
        color: var(--text-black-color);
    }
    .chk-title{
        color: var(--text-black-color);
    }
    .chk-btn{
        width: 300px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .drugs-express-logo{
        width: 250px;
        height: 60px;
    }
    .checkout-wrapper{
        display:flex;
        flex-direction: row;
        padding-top:30px;
        padding-bottom:50px;
        padding-left:50px;
        padding-right:50px;
    }
    .customer-add-paper{
        margin-left: 0px;
        width: 750px;
        height: 120px;
        padding: 5px;
    }
    .customer-add-title{
        margin-left: 20px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .customer-add-contact{
        font-size: 12px;
        color: var(--text-black-color);
    }
    .customer-add-name{
        font-size: 17px;
        color: var(--text-black-color);
    }
    .customer-delivery-loc{
        margin-top: 45px;
        margin-left: 0px;
        padding: 5px;
        color: var(--text-black-color);
        width: 750px;
    }
    .delivery-loc-search{
        box-sizing: border-box;
        border: 1px solid;
        width: 400px;
        height: 32px;
        padding: 0 12px;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        margin-top: 10px;
        margin-bottom:50px;
    }
    .map-wrapper{
        height: 400px;
        width: 735px;
    }
    .customer-payment{
        margin-top: 45px;
        margin-left: 0px;
        width: 750px;
        padding: 5px;
        color: var(--text-black-color);
    }
    .pay-on-delivery-card{
        padding: 5px;
        width: 200px;
        height: 100px;
    }
    .momo-pay-card{
        padding: 5px;
        width: 200px;
        height: 100px;

    }
    .customer-pay-on-dlvr span{
        margin-left: 10px;
    }
    .summary-wrapper{
        display: flex;
        flex: 0.3;
        flex-direction: column;
    }
    .customer-chk-card{
        padding: 5px;
        margin-left: 0;
        width: 350px;
    }

    .customer-chk-sum-title{
        font-size: 18px;
        color: var(--text-black-color);
    }
    .chk-title{
        color: var(--text-black-color);
    }
    .chk-btn{
        width: 300px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .drugs-express-logo{
        width: 250px;
        height: 60px;
    }
    .checkout-wrapper{
        display:flex;
        flex-direction: row;
        padding-top:30px;
        padding-bottom:50px;
        padding-left:50px;
        padding-right:50px;
    }
    .customer-add-paper{
        width: 750px;
        height: 120px;
        padding: 5px;
    }
    .customer-add-title{
        margin-left: 20px;
        color: var(--text-black-color);
        font-weight: bold;
    }
    .customer-add-contact{
        font-size: 12px;
        color: var(--text-black-color);
    }
    .customer-add-name{
        font-size: 17px;
        color: var(--text-black-color);
    }
    .customer-delivery-loc{
        margin-top: 45px;
        padding: 5px;
        color: var(--text-black-color);
        width: 750px;
    }
    .delivery-loc-search{
        box-sizing: border-box;
        border: 1px solid;
        width: 400px;
        height: 32px;
        padding: 0 12px;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        margin-top: 10px;
        margin-bottom:50px;
    }
    .map-wrapper{
        height: 400px;
        width: 735px;
    }
    .customer-payment{
        margin-top: 45px;
        width: 750px;
        padding: 5px;
        color: var(--text-black-color);
    }
    .pay-on-delivery-card{
        padding: 5px;
        width: 200px;
        height: 100px;
    }
    .momo-pay-card{
        padding: 5px;
        width: 200px;
        height: 100px;

    }
    .customer-pay-on-dlvr span{
        margin-left: 10px;
    }
    .summary-wrapper{
        display: flex;
        flex: 0.3;
        flex-direction: column;
    }
    .customer-chk-card{
        padding: 5px;
        width: 350px;
    }

    .customer-chk-sum-title{
        font-size: 18px;
        color: var(--text-black-color);
    }
    .chk-title{
        color: var(--text-black-color);
    }
    .chk-btn{
        width: 300px;
    }
}