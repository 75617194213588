:root{
    --red-color:#EE1C22;
    --light-red-color:#ff6961;
    --teal-color: #96D2C8;
    --text-black-color:#36454F;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-header{
        display: none;
    }
    .mb-header{
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        padding-right: 10px;
        padding-bottom: 5px;
    }
    .mb-header img{
        margin-top: 20px;
        
    }
    .main-layout{
        padding: 0;
    }
    .mob-logged-in{
        display: flex;
        justify-content: flex-start;
        width: 50px;
    }
    .mob-bottom-nav{
        display: block;
    }   
    .mb-header-actions{
        height: 90px;
        width: 90px;
        background-color: var(--light-red-color);
        padding: 7px;
        border-radius: 5px;
        color: white;
    }
    .mb-header-actions-not-logged-in{
        padding: 0;
    }
    .mb-header-action-point{
        background-color:var(--red-color);
        border-radius:'10px';
        padding:'2px';
    }
    .mb-header-action-point-out{
        background-color: whitesmoke;
        color:black;
        padding: 3px;
        border-radius: 5px;
    }
    .mb-header-action-point-out a{
        text-decoration: none;
    }
    .mb-header-action-user-mgt{
        display: flex;
        height: 50%;
        justify-content: space-between;
        align-items: center;
    }
    .no-network{
        display:'flex';
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .search-dialog-title{
        display: flex;
        justify-content: space-between;
    }
    .search-dialog-div{
        width: 80%;
    }
    .search-box-root{

    }
    .search-input{
        margin-right: 10px;
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid green;
    }
    .search-box-form{
        display: flex;
    }
    .submit-icon{
        background-color: white;
        border: none;
        display: none;
    }
    .reset-icon{
        background-color: white;     
        border: none;
    }
    .search-dialog-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
    }
    .search-dialog-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-dialog-pagination .ais-Pagination-item{
        width: 50px;
        font-size: 15px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .dk-header{
        display: none;
    }
    .mb-header{
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        padding-right: 20px;
        padding-bottom: 5px;
    }
    .mb-header a{
        color: gray;
    }
    .mb-header img{
        margin-top: 20px;
        
    }
    .main-layout{
        padding: 0;
    }
    .mob-logged-in{
        display: flex;
        justify-content: flex-start;
        width: 50px;
    }
    .mob-bottom-nav{
        display: block;
    }   
    .mb-header-actions{
        height: 90px;
        width: 90px;
        background-color: var(--light-red-color);
        padding: 7px;
        border-radius: 5px;
        color: white;
    }
    .mb-header-actions-not-logged-in{
        padding: 0;
    }
    .mb-header-action-point{
        background-color:var(--red-color);
        border-radius:'10px';
        padding:'2px';
    }
    .mb-header-action-point-out{
        background-color: whitesmoke;
        color:black;
        padding: 3px;
        border-radius: 5px;
    }
    .mb-header-action-point-out a{
        text-decoration: none;
    }
    .mb-header-action-user-mgt{
        display: flex;
        height: 50%;
        justify-content: space-between;
        align-items: center;
    }
    .no-network{
        display:'flex';
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .search-dialog-title{
        display: flex;
        justify-content: space-between;
    }
    .search-dialog-div{
        width: 80%;
    }
    .search-box-root{

    }
    .search-input{
        margin-right: 10px;
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid green;
    }
    .search-box-form{
        display: flex;
    }
    .submit-icon{
        background-color: white;
        border: none;
        display: none;
    }
    .reset-icon{
        background-color: white;     
        border: none;
    }
    .search-dialog-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
    }
    .search-dialog-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-dialog-pagination .ais-Pagination-item{
        width: 50px;
        font-size: 15px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .dk-header{
        display: none;
    }
    .mb-header{
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        padding-right: 20px;
    }
    .mb-header img{
        margin-top: 20px;
        
    }
    .main-layout{
        padding: 0;
    }
    .no-network{
        display:'flex';
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .search-dialog{
        overflow: hidden;
    }
    .search-dialog-title{
        display: flex;
        justify-content: space-between;
    }  
    .search-dialog-div{
        width: 80%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow-x: hidden;
    }
    .search-dialog-results{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
    }
    .search-dialog-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-dialog-pagination .ais-Pagination-item{
        width: 50px;
        font-size: 15px;
    }
    .search-box-form{
        display: flex;
        width: 500px;
    }
    .search-input{
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid green;
    }
    .submit-icon{
        background-color: white;
        border: none;
    }
    .reset-icon{
        background-color: white;
        border: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .dk-header{
        display: block;
    }
    .mb-header{
        display: none;
    }
    .layout{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    .main-layout{
        width: 100%;
    }
    .mob-bottom-nav{
        display: none;
    }
    .no-network{
        display:'flex';
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .search-dialog{
        overflow: hidden;
    }
    .search-dialog-title{
        display: flex;
        justify-content: space-between;
    }  
    .search-dialog-div{
        width: auto;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
    }
    .search-dialog-results{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
    }
    .search-dialog-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-dialog-pagination .ais-Pagination-item{
        width: 50px;
        font-size: 15px;
    }
    .search-input{
        margin-right: 10px;
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid green;
        margin-left: 130px;
    }
    .submit-icon{
        background-color: white;
        border: none;
    }
    .reset-icon{
        background-color: white;
     
        border: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .dk-header{
        display: block;
    }
    .mb-header{
        display: none;
    }
    .layout{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .main-layout{
        width: 100%;
    }
    .mob-bottom-nav{
        display: none;
    }
    .no-network{
        display:'flex';
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .feedback-div{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        text-decoration: underline;
    }
    .rating-div{
        width: 100%;
        height: 50px;
        padding-bottom: 10px;
        display: flex;
    }
    .search-dialog{
        overflow: hidden;
    }
    .search-dialog-title{
        display: flex;
        justify-content: space-between;
    }  
    .search-dialog-div{
        width: auto;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
    }
    .search-dialog-results{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination{
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
    }
    .search-dialog-pagination .ais-Pagination{
        display: flex;
        justify-content: center;
    }
    .search-dialog-pagination .ais-Pagination-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .search-dialog-pagination .ais-Pagination-item{
        width: 50px;
        font-size: 15px;
    }
    .search-input{
        margin-right: 10px;
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid green;
        margin-left: 130px;
    }
    .submit-icon{
        background-color: white;
        border: none;
    }
    .reset-icon{
        background-color: white;
        border: none;
    }
} 