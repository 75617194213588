.product-title{
    padding: 10px 10px 20px 10px;
    background-color: aliceblue;
    color: lightslategray;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.grid-div{
    background-color: whitesmoke;
    padding: 20px;
}
.progress{
    background-color: none;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}