:root{
    --red-color:#EE1C22;
}


 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .cart-div{
        padding: 10px;
    }
    .cart-table-div{
        display: none;
    }
    .mob-cart-div{
        display: flex;
        flex-direction: column;
        height: 500px;
        overflow-y: scroll;
        margin-bottom: 20px;
    }
    .mob-cart-checkout{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 90px;
        background-color: whitesmoke;
        padding: 10px;
    }
    .cart-checkout{
        display: none;
    }
    .cart-checkout button{
        width: 180px;
    }
    #mob-cart-checkout-amount{
        font-weight: bold;
    }
    .cart-dialog-text{

        padding: 10px;
        text-align: center;
        color: gray;
    }
    .cart-dialog-icon{
        display: flex;
        justify-content: center;
        padding: 5px;
        color: var(--red-color);
        font-size: 30px;
    }
    .cart-update-current{
        background-color: whitesmoke;
        padding: 5px;
        color: black;
    }
    .cart-update-unit-price{
        display: flex;
        /* background-color: coral; */
    }
    .cart-update-quantity{
        display: flex;
    }
    .cart-update-quatity-form{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: black;
    }
    .cart-dialog-action{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
    .cart-no-profile-dialog{
        padding: 10px;
        text-align: center;
        width: 250px;
    }
    .notLoggedIn{
        width: 300px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-weight: bold;
    }
    .notLoggedIn a{
        margin-left: 10px;
    }
    .notLoggedInCloseIcon{
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
        padding-right: 5px;
    }
    .action{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .cart-div{
        padding: 10px;
    }
    .cart-table-div{
        display: none;
    }
    .mob-cart-div{
        display: flex;
        flex-direction: column;
    }
    .cart-checkout{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 90px;
        background-color: whitesmoke;
        padding: 10px;
    }
    .cart-checkout button{
        width: 180px;
    }
    #mob-cart-checkout-amount{
        font-weight: bold;
    }
    .cart-dialog-text{

        padding: 10px;
        text-align: center;
        color: gray;
    }
    .cart-dialog-icon{
        display: flex;
        justify-content: center;
        padding: 5px;
        color: var(--red-color);
        font-size: 30px;
    }
    .cart-update-current{
        background-color: whitesmoke;
        padding: 5px;
        color: black;
    }
    .cart-update-unit-price{
        display: flex;
        /* background-color: coral; */
    }
    .cart-update-quantity{
        display: flex;
    }
    .cart-update-quatity-form{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: black;
    }
    .cart-dialog-action{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
    .cart-no-profile-dialog{
        padding: 10px;
        text-align: center;
        width: 250px;
    }
    .notLoggedIn{
        width: 300px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-weight: bold;
    }
    .notLoggedIn a{
        margin-left: 10px;
    }
    .notLoggedInCloseIcon{
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
        padding-right: 5px;
    }
    .action{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .mob-cart-div{
        display: none;
    }
    .cart-table-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cart-checkout{
        background-color: white;
        padding: 10px;
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .mob-cart-checkout{
        display: none;
    }
    #cart-checkout-amount{
        font-weight: bold;
        margin-bottom: 10px;
    }
    .cart-no-profile-dialog{
        padding: 10px;
        text-align: center;
        width: 250px;
    }
    .notLoggedIn{
        width: 350px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-weight: bold;
    }
    .notLoggedIn a{
        margin-left: 10px;
    }
    .notLoggedInCloseIcon{
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
        padding-right: 5px;
    }
    .action{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .cart-div{
        /* height: 100vh; */
        padding: 10px;
    }
    .mob-cart-div{
        display: none;
    }
    .cart-table-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cart-table{
        width: 900px;
        background-color: whitesmoke;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    #table-head{
        font-weight: bold;
    }
    .cart-checkout{
        background-color: white;
        padding: 10px;
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .mob-cart-checkout{
        display: none;
    }
    #cart-checkout-amount{
        /* margin-right:70px ; */
        font-weight: bold;
    }
    #item-name{
        font-weight: bold;
        color: black;
    }
    .cart-dialog-text{
        width: 350px;
        padding: 10px;
        text-align: center;
        color: gray;
    }
    .cart-dialog-icon{
        display: flex;
        justify-content: center;
        padding: 5px;
        color: var(--red-color);
        font-size: 30px;
    }
    .cart-dialog-action{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
    .cart-no-items{
        width: 100%;
        padding: 10px;
    
    }
    .cart-update-current{
        background-color: whitesmoke;
        padding: 5px;
        color: black;
    }
    .cart-update-unit-price{
        display: flex;
        /* background-color: coral; */
    }
    .cart-update-quantity{
        display: flex;
    }
    .cart-update-quatity-form{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: black;
    }
    .cart-no-profile-dialog{
        padding: 10px;
        text-align: center;
        width: 250px;
    }
    .notLoggedIn{
        width: 500px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-weight: bold;
    }
    .notLoggedIn a{
        margin-left: 10px;
    }
    .notLoggedInCloseIcon{
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
        padding-right: 5px;
    }
    .action{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .cart-div{
        /* height: 100vh; */
        padding: 10px;
    }
    .mob-cart-div{
        display: none;
    }
    .cart-table-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cart-table{
        width: 900px;
        background-color: whitesmoke;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    #table-head{
        font-weight: bold;
    }
    .cart-product-name-img{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* background-color: aqua; */
    }
    .cart-product-name-img img{
        margin-right: 10px;
    }
    .cart-checkout{
        background-color: white;
        padding: 10px;
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .mob-cart-checkout{
        display: none;
    }
    #cart-checkout-amount{
        font-weight: bold;
    }
    #item-name{
        font-weight: bold;
        color: black;
    }
    .cart-dialog-text{
        width: 350px;
        padding: 10px;
        text-align: center;
        color: gray;
    }
    .cart-dialog-icon{
        display: flex;
        justify-content: center;
        padding: 5px;
        color: var(--red-color);
        font-size: 30px;
    }
    .cart-dialog-action{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
    .cart-no-items{
        width: 100%;
        padding: 10px;
    
    }
    .cart-update-current{
        background-color: whitesmoke;
        padding: 5px;
        color: black;
    }
    .cart-update-unit-price{
        display: flex;
        /* background-color: coral; */
    }
    .cart-update-quantity{
        display: flex;
    }
    .cart-update-quatity-form{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: black;
    }
    .cart-no-profile-dialog{
        padding: 10px;
        text-align: center;
        width: 250px;
    }
    .notLoggedIn{
        width: 500px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-weight: bold;
    }
    .notLoggedIn a{
        margin-left: 10px;
    }
    .notLoggedInCloseIcon{
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
        padding-right: 5px;
    }
    .action{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
 } 
