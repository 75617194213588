:root{
    --red-color:#EE1C22;
    --text-black-color:#36454F;
}
 
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .register-container{
        display: flex;
    }
    .back-to-home-reg{
        width: 100%;
    }
    .mob-back-to-home{
        display: flex;
        border-radius: 20px;
        width: 90px;
        height: 30px;
        margin-top: 10px;
        margin-left: 7px;
        border: var(--text-black-color) 1px solid;
        background-color: none;
    }
    .mob-back-to-home a{
        display: flex;
        justify-content: space-around;
        background-color: none;
        align-items: center;
        text-decoration: none;
        width: inherit;
        height: inherit;
        padding-left: 5px;
        padding-right: 5px;
        color: var(--text-black-color);
    }
    .back-home-btn{
        display: none;
    }
    .register-div{
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .name-input{
        width: 50%;
    }
    .register-div-heading{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .register-paper-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
    }
    .register-paper{
        padding: 10px;
        width: 350px;
    }
    .register-paper-heading{
        color: gray;
        padding-top: 10px;
        text-align: center;
        border-bottom: 1px solid gray;
        margin-bottom: 20px;
    }
    .register-error{
        text-align: center;
        color: red;
    }
    .register-logo{
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-top: 10px;
    }
    
    .register-btn-div{
        display: flex;
        justify-content: center;
    }
    .register-btn{
        width: 300px;
    }
    .register-terms{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;
        font-size: small;
    }
    .register-contact{
        height: 100px;
        text-align: center;
        padding: 10px;
        width: 350px;
        margin-top: 10px;
        font-weight: bold;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .register-container{
        display: flex;
    }
    .mob-back-to-home{
        display: flex;
        border-radius: 20px;
    }
    .back-home-btn{
        display: none;
    }
    .register-div{
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .name-input{
        width: 50%;
    }
    .register-div-heading{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .register-paper-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
    }
    .register-paper{
        padding: 10px;
        width: 350px;
    }
    .register-paper-heading{
        color: gray;
        padding-top: 10px;
        text-align: center;
        border-bottom: 1px solid gray;
        margin-bottom: 20px;
    }
    .register-error{
        text-align: center;
        color: red;
    }
    .register-logo{
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-top: 10px;
    }
    
    .register-btn-div{
        display: flex;
        justify-content: center;
    }
    .register-btn{
        width: 300px;
    }
    .register-terms{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;
        font-size: small;
    }
    .register-contact{
        height: 100px;
        text-align: center;
        padding: 10px;
        width: 350px;
        margin-top: 10px;
        font-weight: bold;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .register-container{
        display: flex;
    }
    .back-home-btn{
        display: flex;
        border: var(--text-black-color) 1px solid;
        width: 140px;
        height: 30px;
        border-radius: 20px;
        margin-top: 20px;
        margin-left: 20px;
    }
    .back-home-btn a{
        display: flex;
        width: inherit;
        height: inherit;
        justify-content: space-around;
        align-items: center;
        color: var(--text-black-color);
        padding-left: 5px;
        padding-right: 5px;
    }
    .register-div{
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .name-input{
        width: 50%;
    }
    .register-div-heading{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .register-paper-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
    }
    .register-paper{
        padding: 10px;
        width: 350px;
    }
    .register-paper-heading{
        color: gray;
        padding-top: 10px;
        text-align: center;
        border-bottom: 1px solid gray;
        margin-bottom: 20px;
    }
    .register-error{
        text-align: center;
        color: red;
    }
    .register-logo{
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-top: 10px;
    }
    
    .register-btn-div{
        display: flex;
        justify-content: center;
    }
    .register-btn{
        width: 300px;
    }
    .register-terms{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;
        font-size: small;
    }
    .register-contact{
        height: 100px;
        text-align: center;
        padding: 10px;
        width: 350px;
        margin-top: 10px;
        font-weight: bold;
    }
    .mob-back-to-home{
        display: none;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .register-container{
        display: flex;
    }
    .register-div{
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .name-input{
        width: 50%;
    }
    .register-div-heading{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .register-paper-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
    }
    .register-paper{
        padding: 10px;
        width: 350px;
    }
    .register-paper-heading{
        color: gray;
        padding-top: 10px;
        text-align: center;
        border-bottom: 1px solid gray;
        margin-bottom: 20px;
    }
    .register-error{
        text-align: center;
        color: red;
    }
    .register-logo{
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-top: 30px;
    }
    
    .register-btn-div{
        display: flex;
        justify-content: center;
    }
    .register-btn{
        width: 300px;
    }
    .register-terms{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;
        font-size: small;
    }
    .register-contact{
        height: 100px;
        text-align: center;
        padding: 10px;
        width: 350px;
        margin-top: 10px;
        font-weight: bold;
    }
    .back-home-btn{
        display: flex;
        border: var(--text-black-color) 1px solid;
        width: 140px;
        height: 30px;
        border-radius: 20px;
        margin-top: 20px;
        margin-left: 20px;
    }
    .back-home-btn a{
        display: flex;
        width: inherit;
        height: inherit;
        justify-content: space-around;
        align-items: center;
        color: var(--text-black-color);
        padding-left: 5px;
        padding-right: 5px;
    }
    .mob-back-to-home{
        display: none;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .register-container{
        display: flex;
        background-color: whitesmoke;
    }
    .register-div{
        width: 100%;
        background-color: whitesmoke;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .name-input{
        width: 50%;
    }
    .register-div-heading{
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .register-paper-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
    }
    .register-paper{
        padding: 10px;
        width: 350px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .register-paper-heading{
        color: gray;
        padding-top: 10px;
        text-align: center;
        border-bottom: 1px solid gray;
        margin-bottom: 20px;
    }
    .register-error{
        text-align: center;
        color: red;
    }
    .register-logo{
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-top: 30px;
    }
    
    .register-btn-div{
        display: flex;
        justify-content: center;
    }
    .register-btn{
        width: 300px;
    }
    .register-terms{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;
        font-size: small;
    }
    .register-contact{
        height: 100px;
        text-align: center;
        padding: 10px;
        width: 400px;
        margin-top: 10px;
        font-weight: bold;
    }
    .register-referral{
        width: 400px;
        padding: 10px;
        margin-bottom: 20px;
    }
    .register-referral-btn{
        width: 400px;
        margin-top: 10px;
    }
    .back-home-btn{
        display: flex;
        border: var(--text-black-color) 1px solid;
        width: 140px;
        height: 30px;
        border-radius: 20px;
        margin-top: 20px;
        margin-left: 20px;
    }
    .back-home-btn a{
        display: flex;
        width: inherit;
        height: inherit;
        justify-content: space-around;
        align-items: center;
        color: var(--text-black-color);
        padding-left: 5px;
        padding-right: 5px;
    }
    .mob-back-to-home{
        display: none;
    }
 } 